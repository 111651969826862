/* eslint-disable react/prop-types */
/* eslint-disable valid-jsdoc */
import React from "react";
import MuiSnackbar from "@mui/material/Snackbar";
import PropTypes from "prop-types";
import { variantKinds } from "../../../constants/alertVariantKinds";
import { ClosableAlertMessage } from "../Alert";

const SnackBarCloseReasons = {
    clickaway: "clickaway",
};

/**
 * Variant
 * @typedef {"success" | "error" | "info" | "warning"} Variant
 */

/**
 *  Mui transitions can break due to a missing DOM node
 * - https://mui.com/material-ui/migration/troubleshooting/#cannot-read-property-scrolltop-of-null
 * - https://github.com/mui/material-ui/issues/27154
 */
const DomNode = React.forwardRef((props, ref) => <div ref={ref}>{props.children}</div>);
DomNode.displayName = "DomNode";

/**
 * @typedef SnackbarProps
 * @property {boolean} open - is the snackbar open
 * @property {string} content - content of the snackbar
 * @property {Variant} variant - variant of the snackbar
 * @property {Function} onClose - function to close the snackbar
 * @property {string} className - class name for the snackbar
 * @property {object} anchorOrigin - anchor origin for the snackbar
 */

/**
 * Snackbar component.
 * @param {SnackbarProps} props Component props.
 * @returns {JSX.Element} Snackbar component
 */
const Snackbar = ({
    open = false,
    content,
    variant = variantKinds.error,
    onClose,
    className,
    anchorOrigin,
}) => {
    /**
     * Function to be called on closing the snackbar
     * @param {React.SyntheticEvent | React.MouseEventHandler} _e - ignored close event
     * @param {string} reason - reason snackbar is closing
     * @returns {void}
     */
    const handleClose = (_e, reason = "") => {
        if (reason !== SnackBarCloseReasons.clickaway) {
            onClose();
        }
    };

    return (
        <MuiSnackbar
            key={content}
            anchorOrigin={anchorOrigin ? anchorOrigin : { vertical: "bottom", horizontal: "left" }}
            open={open}
            onClose={handleClose}
            className={className}
        >
            <DomNode>
                <ClosableAlertMessage content={content} variant={variant} onClose={handleClose} />
            </DomNode>
        </MuiSnackbar>
    );
};

Snackbar.propTypes = {
    open: PropTypes.bool,
    variant: PropTypes.string,
    content: PropTypes.node.isRequired,
    onClose: PropTypes.func.isRequired,
    className: PropTypes.string,
    anchorOrigin: PropTypes.object,
};

export default Snackbar;
