import is from "@sindresorhus/is";
import i18n from "i18next";
import languages from "../constants/languages";
import cookieManager from "../services/cookieManager";

/** @typedef {import("../models/accountLanguageKind").AccountLanguageKind} LanguageKind */

/**
 * @typedef Language
 * @property {LanguageKind} key - Key
 * @property {string} displayText - Display text
 * @property {string} languageCode - Language code
 */

/**
 * @function
 * @param {string | null} [languageCode] - the ISO language code
 * @returns {Language} language object where attributes are key, displayText, languageCode
 */
const getLanguageByLanguageCode = (languageCode) =>
    Object.values(languages).find((language) => {
        return languageCode == language.languageCode;
    });

/**
 * @function
 * @param {string} displayText - the display text of a language
 * @returns {Language} language object where attributes are key, displayText, languageCode
 */
const getLanguageByDisplayText = (displayText) =>
    Object.values(languages).find((language) => {
        return displayText == language.displayText;
    });

/**
 * @function
 * @returns {Array<string>} array of language display text strings
 */
const getAllLanguageDisplayTexts = () => Object.values(languages).map((value) => value.displayText);

/**
 * Gets the language object for the current language.
 * @function
 * @returns {Language} current language object
 */
const getCurrentLanguage = () => {
    let accountDetails = cookieManager.getAccountDetails();
    let preferredLanguageCode = accountDetails?.preferredLanguageCode;
    let currentLanguage = getLanguageByLanguageCode(preferredLanguageCode);

    if (is.nullOrUndefined(currentLanguage)) {
        currentLanguage = getLanguageByLanguageCode(i18n.resolvedLanguage);
    }
    if (is.nullOrUndefined(currentLanguage)) {
        currentLanguage = getLanguageByLanguageCode(i18n.options.fallbackLng[0]);
    }

    return currentLanguage;
};

export {
    getLanguageByLanguageCode,
    getLanguageByDisplayText,
    getAllLanguageDisplayTexts,
    getCurrentLanguage,
};
