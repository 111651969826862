import StreamInterceptor from "../../clients/common/interceptors/StreamInterceptor";
import UnaryInterceptor from "../../clients/common/interceptors/UnaryInterceptor";
import { AccountsClient } from "../../grpc/AccountServiceClientPb";

const URI = process.env.REACT_APP_ACCOUNT_URI || "";

const accountsClient = new AccountsClient(URI, null, {
    unaryInterceptors: [new UnaryInterceptor()],
    streamInterceptors: [new StreamInterceptor()],
});

if (process.env.NODE_ENV === "development") {
    // For debugging in Chrome with https://github.com/SafetyCulture/grpc-web-devtools
    const enableDevTools = window?.__GRPCWEB_DEVTOOLS__ || (() => {});
    enableDevTools([accountsClient]);
}

export default accountsClient;
