const CourtFormKind = {
    UnknownCourtForm: "unknownCourtForm",
    Sc100: "SC-100",
    Sc100A: "SC-100A",
    Sc103: "SC-103",
} as const;

type CourtFormKind = typeof CourtFormKind[keyof typeof CourtFormKind];

export { CourtFormKind };
