import React, { useCallback, useMemo, useState } from "react";
import is from "@sindresorhus/is";
import { usePromiseTracker } from "react-promise-tracker";
import { useIsFetching } from "react-query";
import useAuth from "../auth/hooks/useAuth";
import PreloaderContext from "./PreloaderContext";

interface PreloaderProviderProps {
    children: React.ReactNode;
}

const PreloaderProvider = ({ children }: PreloaderProviderProps) => {
    const { promiseInProgress: isTrackingPromise } = usePromiseTracker();

    /*
     *  For the current long-polling in SendBySelfForm's useDemandLettersQuery, the promise tracker wasn't showing the desired behavior
     *  so we use this react-query hook to return a flag indicating the long-polling is in progress. This should only matter for the
     *  userDemandLettersQuery api call, and no other react-query wrapped api calls.
     */
    const numberOfFetchingQueries = useIsFetching(["demandLetterPdf"]);
    const isFetchingQueries = numberOfFetchingQueries > 0;

    const { isValidating: isValidatingAuth } = useAuth();

    const [loaders, setLoaders] = useState<Partial<{ [key: string]: boolean }>>({});

    const setLoader = useCallback((key: string, value: boolean) => {
        setLoaders((prevLoaders) => ({
            ...prevLoaders,
            [key]: value,
        }));
    }, []);

    const isLoaderLoading = Object.values(loaders).some(is.truthy);

    const context = useMemo(
        () => ({
            isLoading:
                isFetchingQueries || isTrackingPromise || isValidatingAuth || isLoaderLoading,
            setLoader,
        }),
        [isFetchingQueries, isLoaderLoading, isTrackingPromise, isValidatingAuth, setLoader]
    );

    return <PreloaderContext.Provider value={context}>{children}</PreloaderContext.Provider>;
};

export default PreloaderProvider;
