import { createContext } from "react";

const noOp = () => {};

interface PreloaderContext {
    isLoading: boolean;
    setLoader: (key: string, value: boolean) => void;
}

const initialContext: PreloaderContext = {
    isLoading: false,
    setLoader: noOp,
};

const PreloaderContext = createContext(initialContext);

export default PreloaderContext;
