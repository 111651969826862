// TODO: MVP-7140 Fix legacy jsdoc/require-property-description rule violations
/* eslint-disable jsdoc/require-property-description */
import React from "react";
import MuiTypography from "@mui/material/Typography";
import styled from "styled-components";
import { FONT_WEIGHT_VARIANTS } from "./typographyKinds";
/** @typedef {import("./typographyKinds").FontSizeEnumType} FontSizeEnumType */
/** @typedef {import("@mui/material/Typography").TypographyProps} TypographyProps */

/**
 * @typedef TypographyPropsType
 * @property {React.ReactNode} children
 * @property {string} [id]
 * @property {FontSizeEnumType} [variant]
 * @property {React.ElementType} [component]
 * @property {React.CSSProperties["fontWeight"] | "semibold"} [fontWeight]
 * @property {string} [className]
 * @property {boolean} [aria-hidden]
 * @property {() => void} [onClick]
 * @property {TypographyProps} [TypographyProps]
 */

// TODO: MVP-3903: change to rems when root font size changes
const StyledTypography = styled(MuiTypography)`
    &.MuiTypography-h1 {
        font-size: 48px;
    }
    &.MuiTypography-h2 {
        font-size: 38px;
    }
    &.MuiTypography-h3 {
        font-size: 34px;
    }
    &.MuiTypography-h4 {
        font-size: 30px;
    }
    &.MuiTypography-h5 {
        font-size: 24px;
    }
    &.MuiTypography-h6 {
        font-size: 20px;
    }
    &.MuiTypography-body1 {
        font-size: 18px;
    }
    &.MuiTypography-body2 {
        font-size: 16px;
    }
    &.MuiTypography-body3 {
        font-size: 15px;
    }
    &.MuiTypography-subtitle1 {
        font-size: 14px;
        line-height: 1.5;
    }
    &.MuiTypography-caption {
        font-size: 13px;
    }
`;

/**
 * @param {TypographyPropsType} props - Props for the Typography component
 * @returns {JSX.Element} - The Typography component
 */
const Typography = (props) => {
    const fontWeight = FONT_WEIGHT_VARIANTS[props.fontWeight] || props.fontWeight;

    return (
        <StyledTypography
            id={props.id}
            aria-live={props["aria-live"]}
            variant={props.variant}
            className={props.className}
            style={{ fontWeight }}
            aria-hidden={props["aria-hidden"]}
            {...props}
        >
            {props.children}
        </StyledTypography>
    );
};

export default Typography;
