import ow from "ow";
import XStateEvent from "../../../clients/XState/Models/XStateEvent";

/**
 * Models an event describing that a Google authentication passed
 */
class GoogleAuthenticationPassedEvent extends XStateEvent {
    static eventType = "AUTHENTICATION.GOOGLE_AUTHENTICATION_PASSED";

    /**
     * Constructs the event
     * @param {string} tokenId - The token id
     * @param {string | null} [preferredLanguageCode] - An optional preferred language in ISO code
     * @param {string | null} [referrer] - An optional referrer
     */
    constructor(tokenId, preferredLanguageCode, referrer) {
        ow(tokenId, ow.string.nonEmpty);
        ow(preferredLanguageCode, ow.any(ow.string, ow.nullOrUndefined));
        ow(referrer, ow.any(ow.string, ow.nullOrUndefined));

        super(GoogleAuthenticationPassedEvent.eventType);

        this.tokenId = tokenId;
        this.preferredLanguageCode = preferredLanguageCode;
        this.referrer = referrer;
    }
}

export default GoogleAuthenticationPassedEvent;
