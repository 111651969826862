import ow from "ow";
import XStateEvent from "../../../clients/XState/Models/XStateEvent";

/**
 * Models an event describing a signup
 */
class SignupEvent extends XStateEvent {
    static eventType = "AUTHENTICATION.SIGNUP";

    /**
     * Constructs the event
     * @param {string} email - The email
     * @param {string} password - The password
     * @param {string} firstName - The first name
     * @param {string} lastName - The lastNAme
     * @param {string} preferredLanguageCode - The preferred language in ISO code
     * @param {string | null} [referrer] - An optional referrer
     */
    constructor(email, password, firstName, lastName, preferredLanguageCode, referrer) {
        ow(email, ow.string.nonEmpty);
        ow(password, ow.string.nonEmpty);
        ow(firstName, ow.string.nonEmpty);
        ow(lastName, ow.string.nonEmpty);
        ow(preferredLanguageCode, ow.string.nonEmpty);

        super(SignupEvent.eventType);

        this.email = email;
        this.password = password;
        this.firstName = firstName;
        this.lastName = lastName;
        this.preferredLanguageCode = preferredLanguageCode;
        this.referrer = referrer;
    }
}

export default SignupEvent;
