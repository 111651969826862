// source: account_common.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
goog.exportSymbol('proto.account_common.GetAccountResponse', null, global);
goog.exportSymbol('proto.account_common.LanguageKind', null, global);
goog.exportSymbol('proto.account_common.ThirdPartyAccount', null, global);
goog.exportSymbol('proto.account_common.ThirdPartyAuthProviderKind', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_common.GetAccountResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.account_common.GetAccountResponse.repeatedFields_, null);
};
goog.inherits(proto.account_common.GetAccountResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_common.GetAccountResponse.displayName = 'proto.account_common.GetAccountResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.account_common.ThirdPartyAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.account_common.ThirdPartyAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.account_common.ThirdPartyAccount.displayName = 'proto.account_common.ThirdPartyAccount';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.account_common.GetAccountResponse.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_common.GetAccountResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.account_common.GetAccountResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_common.GetAccountResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_common.GetAccountResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    email: (f = msg.getEmail()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    firstName: (f = msg.getFirstName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    middleName: (f = msg.getMiddleName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    lastName: (f = msg.getLastName()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    birthDate: (f = msg.getBirthDate()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    phoneNumber: (f = msg.getPhoneNumber()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isAdmin: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    preferredLanguage: jspb.Message.getFieldWithDefault(msg, 9, 0),
    linkedThirdPartyAccountsList: jspb.Message.toObjectList(msg.getLinkedThirdPartyAccountsList(),
    proto.account_common.ThirdPartyAccount.toObject, includeInstance),
    isEmailVerified: (f = msg.getIsEmailVerified()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    peopleClerkUserId: (f = msg.getPeopleClerkUserId()) && google_protobuf_wrappers_pb.StringValue.toObject(includeInstance, f),
    isActive: jspb.Message.getBooleanFieldWithDefault(msg, 13, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_common.GetAccountResponse}
 */
proto.account_common.GetAccountResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_common.GetAccountResponse;
  return proto.account_common.GetAccountResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_common.GetAccountResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_common.GetAccountResponse}
 */
proto.account_common.GetAccountResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setEmail(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setFirstName(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setMiddleName(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setLastName(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBirthDate(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPhoneNumber(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAdmin(value);
      break;
    case 9:
      var value = /** @type {!proto.account_common.LanguageKind} */ (reader.readEnum());
      msg.setPreferredLanguage(value);
      break;
    case 10:
      var value = new proto.account_common.ThirdPartyAccount;
      reader.readMessage(value,proto.account_common.ThirdPartyAccount.deserializeBinaryFromReader);
      msg.addLinkedThirdPartyAccounts(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setIsEmailVerified(value);
      break;
    case 12:
      var value = new google_protobuf_wrappers_pb.StringValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.StringValue.deserializeBinaryFromReader);
      msg.setPeopleClerkUserId(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsActive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_common.GetAccountResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_common.GetAccountResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_common.GetAccountResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_common.GetAccountResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getFirstName();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getMiddleName();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getLastName();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getBirthDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getPhoneNumber();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsAdmin();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getPreferredLanguage();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getLinkedThirdPartyAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.account_common.ThirdPartyAccount.serializeBinaryToWriter
    );
  }
  f = message.getIsEmailVerified();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getPeopleClerkUserId();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_wrappers_pb.StringValue.serializeBinaryToWriter
    );
  }
  f = message.getIsActive();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.account_common.GetAccountResponse.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.account_common.GetAccountResponse} returns this
 */
proto.account_common.GetAccountResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.StringValue email = 2;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_common.GetAccountResponse.prototype.getEmail = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 2));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_common.GetAccountResponse} returns this
*/
proto.account_common.GetAccountResponse.prototype.setEmail = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_common.GetAccountResponse} returns this
 */
proto.account_common.GetAccountResponse.prototype.clearEmail = function() {
  return this.setEmail(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_common.GetAccountResponse.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.StringValue first_name = 3;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_common.GetAccountResponse.prototype.getFirstName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 3));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_common.GetAccountResponse} returns this
*/
proto.account_common.GetAccountResponse.prototype.setFirstName = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_common.GetAccountResponse} returns this
 */
proto.account_common.GetAccountResponse.prototype.clearFirstName = function() {
  return this.setFirstName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_common.GetAccountResponse.prototype.hasFirstName = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.StringValue middle_name = 4;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_common.GetAccountResponse.prototype.getMiddleName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 4));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_common.GetAccountResponse} returns this
*/
proto.account_common.GetAccountResponse.prototype.setMiddleName = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_common.GetAccountResponse} returns this
 */
proto.account_common.GetAccountResponse.prototype.clearMiddleName = function() {
  return this.setMiddleName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_common.GetAccountResponse.prototype.hasMiddleName = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.StringValue last_name = 5;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_common.GetAccountResponse.prototype.getLastName = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 5));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_common.GetAccountResponse} returns this
*/
proto.account_common.GetAccountResponse.prototype.setLastName = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_common.GetAccountResponse} returns this
 */
proto.account_common.GetAccountResponse.prototype.clearLastName = function() {
  return this.setLastName(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_common.GetAccountResponse.prototype.hasLastName = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.protobuf.Timestamp birth_date = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.account_common.GetAccountResponse.prototype.getBirthDate = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.account_common.GetAccountResponse} returns this
*/
proto.account_common.GetAccountResponse.prototype.setBirthDate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_common.GetAccountResponse} returns this
 */
proto.account_common.GetAccountResponse.prototype.clearBirthDate = function() {
  return this.setBirthDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_common.GetAccountResponse.prototype.hasBirthDate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.StringValue phone_number = 7;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_common.GetAccountResponse.prototype.getPhoneNumber = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 7));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_common.GetAccountResponse} returns this
*/
proto.account_common.GetAccountResponse.prototype.setPhoneNumber = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_common.GetAccountResponse} returns this
 */
proto.account_common.GetAccountResponse.prototype.clearPhoneNumber = function() {
  return this.setPhoneNumber(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_common.GetAccountResponse.prototype.hasPhoneNumber = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional bool is_admin = 8;
 * @return {boolean}
 */
proto.account_common.GetAccountResponse.prototype.getIsAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account_common.GetAccountResponse} returns this
 */
proto.account_common.GetAccountResponse.prototype.setIsAdmin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional LanguageKind preferred_language = 9;
 * @return {!proto.account_common.LanguageKind}
 */
proto.account_common.GetAccountResponse.prototype.getPreferredLanguage = function() {
  return /** @type {!proto.account_common.LanguageKind} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.account_common.LanguageKind} value
 * @return {!proto.account_common.GetAccountResponse} returns this
 */
proto.account_common.GetAccountResponse.prototype.setPreferredLanguage = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * repeated ThirdPartyAccount linked_third_party_accounts = 10;
 * @return {!Array<!proto.account_common.ThirdPartyAccount>}
 */
proto.account_common.GetAccountResponse.prototype.getLinkedThirdPartyAccountsList = function() {
  return /** @type{!Array<!proto.account_common.ThirdPartyAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.account_common.ThirdPartyAccount, 10));
};


/**
 * @param {!Array<!proto.account_common.ThirdPartyAccount>} value
 * @return {!proto.account_common.GetAccountResponse} returns this
*/
proto.account_common.GetAccountResponse.prototype.setLinkedThirdPartyAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.account_common.ThirdPartyAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.account_common.ThirdPartyAccount}
 */
proto.account_common.GetAccountResponse.prototype.addLinkedThirdPartyAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.account_common.ThirdPartyAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.account_common.GetAccountResponse} returns this
 */
proto.account_common.GetAccountResponse.prototype.clearLinkedThirdPartyAccountsList = function() {
  return this.setLinkedThirdPartyAccountsList([]);
};


/**
 * optional google.protobuf.BoolValue is_email_verified = 11;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.account_common.GetAccountResponse.prototype.getIsEmailVerified = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 11));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.account_common.GetAccountResponse} returns this
*/
proto.account_common.GetAccountResponse.prototype.setIsEmailVerified = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_common.GetAccountResponse} returns this
 */
proto.account_common.GetAccountResponse.prototype.clearIsEmailVerified = function() {
  return this.setIsEmailVerified(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_common.GetAccountResponse.prototype.hasIsEmailVerified = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.StringValue people_clerk_user_id = 12;
 * @return {?proto.google.protobuf.StringValue}
 */
proto.account_common.GetAccountResponse.prototype.getPeopleClerkUserId = function() {
  return /** @type{?proto.google.protobuf.StringValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.StringValue, 12));
};


/**
 * @param {?proto.google.protobuf.StringValue|undefined} value
 * @return {!proto.account_common.GetAccountResponse} returns this
*/
proto.account_common.GetAccountResponse.prototype.setPeopleClerkUserId = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.account_common.GetAccountResponse} returns this
 */
proto.account_common.GetAccountResponse.prototype.clearPeopleClerkUserId = function() {
  return this.setPeopleClerkUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.account_common.GetAccountResponse.prototype.hasPeopleClerkUserId = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional bool is_active = 13;
 * @return {boolean}
 */
proto.account_common.GetAccountResponse.prototype.getIsActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.account_common.GetAccountResponse} returns this
 */
proto.account_common.GetAccountResponse.prototype.setIsActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.account_common.ThirdPartyAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.account_common.ThirdPartyAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.account_common.ThirdPartyAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_common.ThirdPartyAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    authProvider: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.account_common.ThirdPartyAccount}
 */
proto.account_common.ThirdPartyAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.account_common.ThirdPartyAccount;
  return proto.account_common.ThirdPartyAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.account_common.ThirdPartyAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.account_common.ThirdPartyAccount}
 */
proto.account_common.ThirdPartyAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.account_common.ThirdPartyAuthProviderKind} */ (reader.readEnum());
      msg.setAuthProvider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.account_common.ThirdPartyAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.account_common.ThirdPartyAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.account_common.ThirdPartyAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.account_common.ThirdPartyAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthProvider();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional ThirdPartyAuthProviderKind auth_provider = 1;
 * @return {!proto.account_common.ThirdPartyAuthProviderKind}
 */
proto.account_common.ThirdPartyAccount.prototype.getAuthProvider = function() {
  return /** @type {!proto.account_common.ThirdPartyAuthProviderKind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.account_common.ThirdPartyAuthProviderKind} value
 * @return {!proto.account_common.ThirdPartyAccount} returns this
 */
proto.account_common.ThirdPartyAccount.prototype.setAuthProvider = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.account_common.LanguageKind = {
  ENGLISH: 0,
  SPANISH: 1
};

/**
 * @enum {number}
 */
proto.account_common.ThirdPartyAuthProviderKind = {
  UNKNOWN_THIRD_PARTY_AUTH_KIND: 0,
  GOOGLE_AUTH: 1
};

goog.object.extend(exports, proto.account_common);
