// TODO: MVP-7147 Fix legacy jsdoc/require-returns rule violations
/* eslint-disable jsdoc/require-returns */
/* eslint-disable react/display-name */
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";

/**
 * Higher Order Component to inject useMediaQuery hook into class-based components.
 * @param {...any} args - Arguments to pass underlying useMediaQuery hook
 */
const withMediaQuery =
    (...args) =>
    (Component) =>
    (props) => {
        const mediaQuery = useMediaQuery(...args);
        return <Component mediaQuery={mediaQuery} {...props} />;
    };

export default withMediaQuery;
