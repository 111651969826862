import XStateEvent from "../../../clients/XState/Models/XStateEvent";

/**
 * Models an event describing a logout
 */
class LogoutEvent extends XStateEvent {
    static eventType = "AUTHENTICATION.LOGOUT";

    /**
     * Constructs the event
     */
    constructor() {
        super(LogoutEvent.eventType);
    }
}

export default LogoutEvent;
