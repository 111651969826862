import React, { useCallback } from "react";
import is from "@sindresorhus/is";
import { useMachine } from "@xstate/react";
import createAuthMachine, {
    states as authMachineStates,
    events,
} from "../../services/authentication";
import AuthContext from "./AuthContext";

const {
    GoogleAuthenticationFailedEvent,
    GoogleAuthenticationPassedEvent,
    LoginEvent,
    LogoutEvent,
    RemoveHasRecentlyLoggedInEvent,
    ResetErrorsEvent,
    SignupEvent,
    ResetExpireSessionEvent,
    ExpireSessionEvent,
    ResetSessionExpiredEvent,
    SessionExpiredEvent,
} = events;

/**
 * @typedef AuthProviderProps
 * @property {React.ReactElement} children - Children.
 */

/**
 * AuthProvider
 * @param {AuthProviderProps} props - AuthProvider props.
 * @returns {React.ReactElement} - AuthProvider component.
 */
const AuthProvider = ({ children }) => {
    // Use lazy initialization to prevent changing authMachine instances from being passed to useMachine
    const [authMachineState, authMachineSend] = useMachine(() => createAuthMachine());

    const isAuthenticating = [authMachineStates.loggingIn, authMachineStates.signingUp].includes(
        authMachineState.value
    );
    const isValidating = authMachineState.value === authMachineStates.validatingAuthentication;
    const isLoggedIn = authMachineState.value === authMachineStates.authenticated;

    const hasError =
        is.boolean(authMachineState?.context?.hasError) && authMachineState.context.hasError;
    const errorCode = authMachineState?.context?.errorCode;

    const hasRecentlyLoggedIn = authMachineState?.context?.hasRecentlyLoggedIn;
    const shouldExpireSession = authMachineState?.context?.shouldExpireSession;
    const isSessionExpired = authMachineState?.context?.isSessionExpired;

    const onReset = useCallback(() => {
        authMachineSend(new ResetErrorsEvent());
    }, [authMachineSend]);

    const onSignup = useCallback(
        (firstName, lastName, email, password, preferredLanguageCode, referrer) => {
            authMachineSend(
                new SignupEvent(
                    email,
                    password,
                    firstName,
                    lastName,
                    preferredLanguageCode,
                    referrer
                )
            );
        },
        [authMachineSend]
    );

    const onLogin = useCallback(
        (email, password) => {
            authMachineSend(new LoginEvent(email, password));
        },
        [authMachineSend]
    );

    const onLogout = useCallback(() => {
        authMachineSend(new LogoutEvent());
    }, [authMachineSend]);

    const onGoogleAuthenticationPassed = useCallback(
        (tokenId, preferredLanguageCode, referrer) => {
            authMachineSend(
                new GoogleAuthenticationPassedEvent(tokenId, preferredLanguageCode, referrer)
            );
        },
        [authMachineSend]
    );

    const onGoogleAuthenticationFailed = useCallback(() => {
        authMachineSend(new GoogleAuthenticationFailedEvent());
    }, [authMachineSend]);

    const onRemoveHasRecentlyLoggedIn = useCallback(() => {
        authMachineSend(new RemoveHasRecentlyLoggedInEvent());
    }, [authMachineSend]);

    const onResetExpireSession = useCallback(() => {
        authMachineSend(new ResetExpireSessionEvent());
    }, [authMachineSend]);

    const onExpireSession = useCallback(() => {
        if (isValidating || !isLoggedIn) {
            return;
        }
        authMachineSend(new ExpireSessionEvent());
    }, [isValidating, isLoggedIn, authMachineSend]);

    const onResetSessionExpired = useCallback(() => {
        authMachineSend(new ResetSessionExpiredEvent());
    }, [authMachineSend]);

    const onSessionExpired = useCallback(() => {
        authMachineSend(new SessionExpiredEvent());
    }, [authMachineSend]);

    return (
        <AuthContext.Provider
            value={{
                isAuthenticating,
                isValidating,
                isLoggedIn,
                hasError,
                errorCode,
                hasRecentlyLoggedIn,
                shouldExpireSession,
                isSessionExpired,
                authMachineActions: {
                    onReset,
                    onSignup,
                    onLogin,
                    onLogout,
                    onGoogleAuthenticationPassed,
                    onGoogleAuthenticationFailed,
                    onRemoveHasRecentlyLoggedIn,
                    onResetExpireSession,
                    onExpireSession,
                    onResetSessionExpired,
                    onSessionExpired,
                },
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
