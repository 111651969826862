import { useLocation } from "react-router-dom";
import { authRoutes } from "../constants/globalRoutes";

/**
 * Hook that indicates whether a custom layout should be used
 * @returns Boolean indicating whether it's a custom layout
 */
const useIsCustomLayout = (): boolean => {
    const { pathname } = useLocation();

    return (
        pathname.includes(authRoutes.plainSignUp) ||
        pathname.includes(authRoutes.login) ||
        pathname.includes(authRoutes.forgotPassword)
    );
};

export default useIsCustomLayout;
