import { useEffect } from "react";
import is from "@sindresorhus/is";
import ow from "ow";
import { install } from "resize-observer";

// Support older browsers
if (!window.ResizeObserver) {
    install();
}

/**
 * @typedef  {object} ref
 * @property {*} current - The current reference
 */

/**
 * Hook to track DOM elements in the viewport and invoke a callback when sizing changes are detected
 * See https://web.dev/resize-observer/ for more information
 * @param {object}   param          The parameters
 * @param {ref}      param.target   Ref for the observed target
 * @param {Function} param.onResize Function to invoke when a resize event has occurred
 * @param {boolean}  param.enabled  Flag to enable or disable hook execution
 * @returns {void}
 */
const useResizeObserver = ({ target, onResize, enabled = false }) => {
    ow(
        target,
        ow.any(
            ow.nullOrUndefined,
            ow.object.partialShape({
                current: ow.any(ow.array.ofType(ow.object), ow.object, ow.nullOrUndefined),
            })
        )
    );
    ow(onResize, ow.function);
    ow(enabled, ow.boolean);

    useEffect(() => {
        if (!is.nullOrUndefined(target?.current)) {
            if (enabled === false) {
                return;
            }

            const observer = new ResizeObserver(onResize);

            const element = target.current;

            if (is.array(element)) {
                element.forEach((el) => observer.observe(el));
            } else {
                observer.observe(element);
            }

            return () => {
                observer.disconnect();
            };
        }
    }, [onResize, target, enabled]);
};

export default useResizeObserver;
