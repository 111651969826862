import React from "react";
import { FlagProvider, type IFlagProvider } from "@unleash/proxy-client-react";
import FlagsChecker from "./components/FlagsChecker";

interface FeatureFlagsProviderProps extends IFlagProvider {
    children: React.ReactElement;
}

const FeatureFlagsProvider = ({ children, ...rest }: FeatureFlagsProviderProps) => (
    <FlagProvider {...rest}>
        <FlagsChecker>{children}</FlagsChecker>
    </FlagProvider>
);

export default FeatureFlagsProvider;
