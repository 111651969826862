import { createContext } from "react";

const noOp = () => {};

/**
 * @callback AddSnackFunction
 * @param {ReactNode} content
 * @param {{variant?: string, className?: string}} [options]
 * @returns {void}
 */

/**
 * @typedef {AddSnackFunction} SnackbarContext
 */

/** @type {SnackbarContext} */
const initialContext = noOp;

const SnackbarContext = createContext(initialContext);

export default SnackbarContext;
