import ow from "ow";

/**
 * Models an XState event
 * https://xstate.js.org/docs/guides/events.html#api
 */
class XStateEvent {
    /**
     * Constructs an XState event
     * @param {string} eventType - The XState event type
     */
    constructor(eventType) {
        ow(eventType, ow.string.nonEmpty);

        this.type = eventType;
    }
}

export default XStateEvent;
