import GrpcStatusCodes from "../../../grpc/GrpcStatusCodes";
import authSessionEventListener from "../../../provider/authSession/authSessionEventListener";

const UnaryInterceptor = function () {};

UnaryInterceptor.prototype.intercept = function (request, invoker) {
    return invoker(request).catch((err) => {
        if (err.code === GrpcStatusCodes.statusCodes.unauthenticated) {
            authSessionEventListener.expireSession();
        }

        throw err;
    });
};

export default UnaryInterceptor;
