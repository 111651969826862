import ow from "ow";
import XStateEvent from "../../../clients/XState/Models/XStateEvent";

/**
 * Models an event describing a login
 */
class LoginEvent extends XStateEvent {
    static eventType = "AUTHENTICATION.LOGIN";

    /**
     * Constructs the event
     * @param {string} email - The email
     * @param {string} password - The password
     */
    constructor(email, password) {
        ow(email, ow.string.nonEmpty);
        ow(password, ow.string.nonEmpty);

        super(LoginEvent.eventType);

        this.email = email;
        this.password = password;
    }
}

export default LoginEvent;
