import { createContext } from "react";

/**
 * @typedef GoogleAuthContext
 * @property {boolean} scriptLoadedSuccessfully - Whether or not the required Google Auth script loaded successfully.
 */

/** @type {GoogleAuthContext} */
const initialContext = {
    scriptLoadedSuccessfully: false,
};

const GoogleAuthContext = createContext(initialContext);

export default GoogleAuthContext;
