// TODO: MVP-7147 Fix legacy jsdoc/require-returns rule violations
/* eslint-disable jsdoc/require-returns */
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
/** @typedef {import("@mui/material").CircularProgressProps} CircularProgressProps */

/**
 *
 * @param {{centerAlign?: boolean, className?: string, size?: number}} props - Props for the Spinner component
 */
const Spinner = ({ centerAlign = false, className, size = 40, color = "primary" }) => {
    const classes = twMerge(
        "z-[1] text-['var(--color-secondary)']",
        centerAlign && "absolute top-1/2 left-[calc(50%-20px)]",
        className
    );
    return <CircularProgress className={classes} size={size} color={color} />;
};

Spinner.propTypes = {
    className: PropTypes.string,
    centerAlign: PropTypes.bool,
    size: PropTypes.number,
    color: PropTypes.string,
};

export default Spinner;
