import XStateEvent from "../../../clients/XState/Models/XStateEvent";

/**
 * Models an event describing a change in the auth context for hasRecentlyLoggedIn
 */
class RemoveHasRecentlyLoggedInEvent extends XStateEvent {
    static eventType = "AUTHENTICATION.REMOVE_RECENT_LOGIN";

    /**
     * Constructs the event
     */
    constructor() {
        super(RemoveHasRecentlyLoggedInEvent.eventType);
    }
}

export default RemoveHasRecentlyLoggedInEvent;
