import { createContext } from "react";

/** @typedef {import("../../services/AccountService").ErrorCode} ErrorCode */

const noOp = () => {};

/**
 * @typedef AuthContextActions
 * @property {() => void} onReset - Resets authentication errors.
 * @property {(firstName: string, lastName: string, email: string, password: string, preferredLanguageCode: ?string, referrer: ?string) => void} onSignup - Sign up.
 * @property {(email: string, password: string) => void} onLogin - Log in.
 * @property {() => void} onLogout - Log out.
 * @property {(tokenId: string, preferredLanguageCode: string, referrer: ?string) => void} onGoogleAuthenticationPassed - Indicates google authentication succeeded.
 * @property {() => void} onGoogleAuthenticationFailed - Indicates google authentication failed.
 * @property {() => void} onRemoveHasRecentlyLoggedIn - Resets the flag that indicates if the user recently logged in.
 * @property {() => void} onResetExpireSession - Resets the flag that indicates that the user's session should expire.
 * @property {() => void} onExpireSession - Indicates the user's session should expire.
 * @property {() => void} onResetSessionExpired - Resets the flag that indicates that the user's session expired.
 * @property {() => void} onSessionExpired - Expires the user's session.
 */

/**
 * @typedef AuthContext
 * @property {boolean} isValidating - Whether or not the user's authentication status is being validated.
 * @property {boolean} isAuthenticating - Whether or not the authentication is in progress.
 * @property {boolean} isLoggedIn - Whether or not the user is logged in.
 * @property {boolean} hasError - Whether or not there's an authentication error.
 * @property {ErrorCode | null} errorCode - The error code if there's an authentication error.
 * @property {boolean} hasRecentlyLoggedIn - Whether or not the user recently logged in.
 * @property {boolean} shouldExpireSession - Whether or not the user's session should expire.
 * @property {boolean} isSessionExpired - Whether or not the user's session has expired.
 * @property {AuthContextActions} authMachineActions - Functions to communicate with the Auth machine.
 */

/** @type {AuthContext} */
const initialContext = {
    isValidating: false,
    isAuthenticating: false,
    isLoggedIn: false,
    hasError: false,
    errorCode: null,
    hasRecentlyLoggedIn: false,
    shouldExpireSession: false,
    isSessionExpired: false,
    authMachineActions: {
        onReset: noOp,
        onSignup: noOp,
        onLogin: noOp,
        onLogout: noOp,
        onGoogleAuthenticationPassed: noOp,
        onGoogleAuthenticationFailed: noOp,
        onRemoveHasRecentlyLoggedIn: noOp,
        onResetExpireSession: noOp,
        onExpireSession: noOp,
        onResetSessionExpired: noOp,
        onSessionExpired: noOp,
    },
};

const AuthContext = createContext(initialContext);

export default AuthContext;
