const DefendantDefenseKind = {
    Unknown: "unknown",
    // Defense kind 1: disputes one or more elements; claims to owe nothing
    DisputesClaim: "disputesClaim",
    // Defense kind 2: does not dispute elements, but disputes the damages amount
    DisputesDamages: "disputesDamages",
    // Defense kind 3: does not dispute
    DoesNotDispute: "doesNotDispute",
} as const;

type DefendantDefenseKind = typeof DefendantDefenseKind[keyof typeof DefendantDefenseKind];

export { DefendantDefenseKind };
