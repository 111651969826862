import { StringValue } from "google-protobuf/google/protobuf/wrappers_pb";

const toGrpcStringValue = (value: string): StringValue => {
    const result = new StringValue();
    result.setValue(value);

    return result;
};

export { toGrpcStringValue };
