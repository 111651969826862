import ExpireSessionEvent from "./events/ExpireSessionEvent";

class AuthSessionEventListener extends EventTarget {
    expireSession() {
        this.dispatchEvent(new ExpireSessionEvent());
    }
}

const authSessionEventListener = new AuthSessionEventListener();

export default authSessionEventListener;
