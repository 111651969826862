import XStateEvent from "../../../clients/XState/Models/XStateEvent";

/**
 * Models an event describing a request to reset the flag that indicates
 * that the user's session has expired.
 */
class ResetSessionExpiredEvent extends XStateEvent {
    static eventType = "AUTHENTICATION.RESET_SESSION_EXPIRED";

    /**
     * Constructs the event
     */
    constructor() {
        super(ResetSessionExpiredEvent.eventType);
    }
}

export default ResetSessionExpiredEvent;
