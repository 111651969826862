import AccountService from "../AccountService";
import authMachine from "./authMachine";

/**
 * Function to create the auth machine
 * @returns {object} The auth machine
 */
const createAuthMachine = () => authMachine(AccountService);

export default createAuthMachine;
export * as events from "./events";
export { default as states } from "./states";
