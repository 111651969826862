import { createContext } from "react";

const noOp = () => {};

/**
 * @typedef EmailVerificationContext
 * @property {boolean} isLoading - Whether we are loading the email verification token.
 * @property {boolean} wasVerificationTokenFound - The email verification token was found from the query string params.
 * @property {() => void} clearWasVerificationTokenFound - Clears the flag indicating that an email verification token was found in the query string params.
 * @property {string} verificationToken - The email verification token.
 * @property {() => void} clearVerificationToken - Clears the email verification token.
 */

/** @type {EmailVerificationContext} */
const initialContext = {
    isLoading: null,
    wasVerificationTokenFound: false,
    clearWasVerificationTokenFound: noOp,
    verificationToken: null,
    clearVerificationToken: noOp,
};

const EmailVerificationContext = createContext(initialContext);

export default EmailVerificationContext;
