import XStateEvent from "../../../clients/XState/Models/XStateEvent";

/**
 * Models an event describing a resetting authentication-related errors
 */
class ResetErrorsEvent extends XStateEvent {
    static eventType = "AUTHENTICATION.CLEAR_ERRORS";

    /**
     * Constructs the event
     */
    constructor() {
        super(ResetErrorsEvent.eventType);
    }
}

export default ResetErrorsEvent;
