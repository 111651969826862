import { createTheme } from "@mui/material/styles";

export const muiTheme = createTheme({
    breakpoints: {
        values: {
            xxs: 0,
            xs: 480,
            sm: 768,
            md: 1000,
            lg: 1200,
            xl: 1300,
        },
    },
    components: {
        MuiTextField: {
            defaultProps: {
                // as of Mui v5, this defaults to "outlined"
                // this reverts the default to be "standard"
                variant: "standard",
            },
        },
        MuiFormControl: {
            defaultProps: {
                variant: "standard",
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: "standard",
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontSize: "0.875rem",
                    lineHeight: 1.43,
                    letterSpacing: "0.01071em",
                },
            },
        },
    },
});
