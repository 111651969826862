import is from "@sindresorhus/is";
import languages from "../../../constants/languages";
import { LanguageKind as GrpcLanguageKind } from "../../../grpc/account_common_pb";
import { AccountLanguageKind as LanguageKind } from "../../../models/accountLanguageKind";

interface Language {
    key: LanguageKind;
    displayText: string;
    languageCode: string;
}

const mapGrpcLanguageKindToLanguage = (grpcLanguageKind?: GrpcLanguageKind | null) => {
    if (is.nullOrUndefined(grpcLanguageKind)) {
        return null;
    }

    switch (grpcLanguageKind) {
        case GrpcLanguageKind.ENGLISH:
            return languages.english;
        case GrpcLanguageKind.SPANISH:
            return languages.spanish;
    }
};

const mapLanguageToGrpcLanguageKind = (language?: Language | null) => {
    if (is.nullOrUndefined(language)) {
        return null;
    }

    switch (language.key) {
        case LanguageKind.English:
            return GrpcLanguageKind.ENGLISH;
        case LanguageKind.Spanish:
            return GrpcLanguageKind.SPANISH;
    }
};

export { mapGrpcLanguageKindToLanguage, mapLanguageToGrpcLanguageKind };
