import ow from "ow";
import XStateEvent from "../../../clients/XState/Models/XStateEvent";

/**
 * Models an event describing an error has occurred
 */
class ErrorEvent extends XStateEvent {
    static eventType = "AUTHENTICATION.AUTHENTICATION_ERROR";

    /**
     * Constructs the event
     * @param {string} [errorMessage] - An optional error message
     * @param {string} [code] - An optional error code
     */
    constructor(errorMessage, code) {
        ow(errorMessage, ow.any(ow.nullOrUndefined, ow.string));
        ow(code, ow.any(ow.nullOrUndefined, ow.string));

        super(ErrorEvent.eventType);

        this.errorMessage = errorMessage;
        this.code = code;
    }
}

export default ErrorEvent;
