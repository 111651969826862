import XStateEvent from "../../../clients/XState/Models/XStateEvent";

/**
 * Models an event describing a request to set the user's session as expired.
 */
class SessionExpiredEvent extends XStateEvent {
    static eventType = "AUTHENTICATION.SESSION_EXPIRED";

    /**
     * Constructs the event
     */
    constructor() {
        super(SessionExpiredEvent.eventType);
    }
}

export default SessionExpiredEvent;
