import { t as translate } from "i18next";
import log from "loglevel";

const GenericClaimKind = {
    Unknown: "unknown",
    Defamation: "defamation",
    Nuisance: "nuisance",
    TrespassToChattels: "trespassToChattels",
    Conversion: "conversion",
    Other: "other",
    Negligence: "negligence",
    StrictLiability: "strictLiability",
    IntentionalTort: "intentionalTort",
} as const;

type GenericClaimKind = typeof GenericClaimKind[keyof typeof GenericClaimKind];

const ClaimKind = {
    ...GenericClaimKind,
    BreachOfContractLoan: "breachOfContractLoan",
} as const;
type ClaimKind = typeof ClaimKind[keyof typeof ClaimKind];

const isGenericClaimClaimKind = (claimKind: string): claimKind is GenericClaimKind =>
    Object.values(GenericClaimKind).includes(claimKind as GenericClaimKind);

const mapClaimKindToI18nValue = (claimKind: ClaimKind): string => {
    switch (claimKind) {
        case ClaimKind.Unknown:
            return translate("common.claim_kind_unknown");
        case ClaimKind.Defamation:
            return translate("common.claim_kind_defamation");
        case ClaimKind.BreachOfContractLoan:
            return translate("common.claim_kind_breach_of_contract");
        case ClaimKind.Nuisance:
            return translate("common.claim_kind_nuisance");
        case ClaimKind.TrespassToChattels:
            return translate("common.claim_kind_trespass_to_chattels");
        case ClaimKind.Conversion:
            return translate("common.claim_kind_conversion");
        case ClaimKind.Other:
            return translate("common.claim_kind_other");
        case ClaimKind.Negligence:
            return translate("common.claim_kind_negligence");
        case ClaimKind.StrictLiability:
            return translate("common.claim_kind_strict_liability");
        case ClaimKind.IntentionalTort:
            return translate("common.claim_kind_intentional_tort");
        default:
            log.error(`Unknown claim kind: ${claimKind}`);
            return translate("common.claim_kind_unknown");
    }
};

export { ClaimKind, isGenericClaimClaimKind, mapClaimKindToI18nValue };
