import React, { useEffect, useState, useCallback } from "react";
import is from "@sindresorhus/is";
import Snackbar from "../../components/common/Snackbar/Snackbar";
import SnackbarContext from "./SnackbarContext";

/**
 * Snack object
 * @typedef Snack
 * @property {ReactNode} [content] - snack content
 * @property {"success" | "error" | "info" | "warning"} [variant] - type of snack (defaults to "error")
 * @property {string} [className] - Classname to be applied to the Snack
 * @property {number} [timeout] - timeout in ms
 * @property {string} [id] - id for the snack
 */

/**
 * @typedef SnackbarProviderProps
 * @property {React.ReactElement} children - Children.
 */

/**
 * SnackbarProvider - Manages the snackbars.
 * @param {SnackbarProviderProps} props - SnackbarProvider props.
 * @returns {React.ReactElement} - SnackbarProvider component.
 */
const SnackbarProvider = ({ children }) => {
    /** @type {[Snack[], React.Dispatch<React.SetStateAction<Snack[]>>]} */
    const [snacks, setSnacks] = useState([]);
    /** @type {[Snack, React.Dispatch<React.SetStateAction<Snack>>]} */
    const [currentSnack, setCurrentSnack] = useState({});

    // When the snacks array changes size, set the current snack
    useEffect(() => {
        if (snacks.length > 0) {
            const lastSnack = snacks[snacks.length - 1];
            setCurrentSnack(lastSnack);
        }
    }, [snacks]);

    /**
     * Removes the last snack from the snacks array
     * @returns {void}
     */
    const removeCurrentSnack = useCallback(() => {
        setCurrentSnack({});
        const snacksWithoutLastElement = snacks.slice(0, snacks.length - 1);
        setSnacks(snacksWithoutLastElement);
    }, [snacks]);

    // When the currentSnack changes, if applicable, set the timeout for the currentSnack
    useEffect(() => {
        if (
            !is.nullOrUndefined(currentSnack?.content) &&
            !is.nullOrUndefined(currentSnack.timeout)
        ) {
            const timeout = currentSnack?.timeout;

            const timer = setTimeout(() => {
                removeCurrentSnack();
            }, timeout);

            return () => clearTimeout(timer);
        }
    }, [currentSnack, removeCurrentSnack]);

    // Memoized setter for adding snackbars
    const addSnack = useCallback((content, options) => {
        setSnacks((snacks) => {
            // Prevent duplicate messages
            if (snacks.some((snack) => snack.id === options.id)) {
                return snacks;
            } else {
                const newSnack = { content, ...options };
                return [newSnack, ...snacks];
            }
        });
    }, []);

    const handleSnackbarClose = () => {
        removeCurrentSnack();
    };

    return (
        <SnackbarContext.Provider value={addSnack}>
            {children}
            {!is.nullOrUndefined(currentSnack?.content) && (
                <Snackbar
                    key={currentSnack.content}
                    open={true}
                    onClose={handleSnackbarClose}
                    content={currentSnack.content}
                    variant={currentSnack?.variant}
                    className={currentSnack.className}
                />
            )}
        </SnackbarContext.Provider>
    );
};

export default SnackbarProvider;
