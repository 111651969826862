/*
Function to handle ChunkLoadError.

If the app encounters a chunk load error, then we will try to reload the page to try and trigger the browser to 
download an up-to-date bundle with valid chunk references.

The idea of a "reload loop" is a small bit of complexity to handle an edge case where we
want to allow one user session to recover from multiple ChunkLoadErrors throughout the course of their session, since 
a successful recovery does not clean up local storage. 

A simple way to think about the concept of a reload loop in this code is "we only retry once per reload interval".
 */
import userTrackingClient from "../../clients/UserTracking";
import localStorageKeys from "../../localStorageKeys";
const { chunkLoadFailureDataKey } = localStorageKeys;

interface FailureData {
    // The Unix epoch time of the first error that triggered the "current" refresh cycle.
    recoveryStartTime: number;

    // The number of recoveryAttempts that were attempted for the "current" refresh cycle.
    recoveryAttempts: number;
}

const recoverySettings = {
    maxRecoveryAttemptsPerLoop: 1,
    cycleRefreshTime: 5 * 60 * 1000, // Somewhat arbitrary selection of five minutes.
};

export const handleChunkLoadError = (error: Error) => {
    const currentTime = new Date().getTime();
    const failureData: FailureData = JSON.parse(
        window.localStorage.getItem(chunkLoadFailureDataKey) ??
            JSON.stringify({ recoveryStartTime: currentTime, recoveryAttempts: 0 })
    );

    // If the last recorded error is old enough, then refresh the data for this new loop.
    const isSameReloadLoop =
        currentTime - failureData.recoveryStartTime < recoverySettings.cycleRefreshTime;
    if (!isSameReloadLoop) {
        failureData.recoveryStartTime = currentTime;
        failureData.recoveryAttempts = 0;
    }

    // Recover or throw depending on whether we've already tried reloading in this loop.
    if (failureData.recoveryAttempts < recoverySettings.maxRecoveryAttemptsPerLoop) {
        failureData.recoveryAttempts++;
        window.localStorage.setItem(chunkLoadFailureDataKey, JSON.stringify(failureData));
        window.location.reload();
    } else {
        userTrackingClient.trackAppCrash(error);
    }
};
