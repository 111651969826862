import type AccountServiceErrorCode from "./ErrorCode";

class AccountServiceError extends Error {
    code: AccountServiceErrorCode;

    constructor(message: string, code: AccountServiceErrorCode) {
        super(message);
        this.name = "AccountServiceError";
        this.code = code;
    }
}

export default AccountServiceError;
