import { useEffect, useRef } from "react";
import is from "@sindresorhus/is";
import { differenceInMilliseconds, differenceInMinutes, isFuture, isPast } from "date-fns";

const TIMEOUT_MINUTES = 60;
const DATE_CHECK_INTERVAL_MINUTES = 60;

/**
 * useTimerUntilDate
 * This hook will run the provided callback when the provided date (and time) is reached.
 * @example
 * const futureDate = new Date(Date.now() + 10 * 60 * 1000); // Date that is 10 minutes in the future.
 * useTimerUntilDate(futureDate, () => console.log("Executed after 10 minutes!"));
 * @param {Date | null} date - The date in which we want the callback function to run. If the date provided is null,
 * no timer will be scheduled/executed.
 * @param {() => void} callback - The function that should be executed when the specified date (and time) is reached.
 */
const useTimerUntilDate = (date, callback) => {
    /**
     * This hook provides a way to execute a function when the provided date (and time) is reached.
     * setTimeout has a limit of 2,147,483,647 ms (24.8 days). For this reason setTimeout will not work
     * for all possible dates in the future.
     *
     * To overcome this limitation, for dates that are more than 1 hour in the future, instead of setting
     * a timer (setTimeout), we set a 1 hour interval (setInterval). Every hour we check how much time is
     * left. If the remaining time is less than 1 hour, we remove the interval and set a timer, after which
     * the callback function will be executed. If the remaining time is more than 1 hour, the interval continues
     * to run hourly until the remaining time is less than 1 hour.
     *
     * If the provided date is less than 1 hour, a timer (setTimeout) is immediately set, after which the callback
     * function will be executed.
     *
     * If the provided date is in the past, the callback function runs immediately.
     */

    const intervalRef = useRef(null);
    const timeoutRef = useRef(null);

    useEffect(() => {
        // Clears the interval (if any).
        const removeInterval = () => {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        };

        // Clears the timeout (if any).
        const removeTimeout = () => {
            clearInterval(timeoutRef.current);
            timeoutRef.current = null;
        };

        // Executes the callback and cleans up both the interval and timeout.
        const runCallback = () => {
            removeInterval();
            removeTimeout();
            callback();
        };

        // Sets up a timeout to run the callback at the specified date and time.
        const createTimeout = () => {
            removeTimeout();
            removeInterval();

            // Check how many milliseconds until the timer needs to run.
            const msToTime = differenceInMilliseconds(date, new Date());

            // Add a timer to run callback when the time is right.
            timeoutRef.current = setTimeout(runCallback, msToTime);
        };

        /**
         * Checks if the remaining time to the specified date is less than or equal to 1 hour
         * and creates a timeout accordingly.
         */
        const checkIfShouldCreateTimeout = () => {
            const minutesUntilDate = differenceInMinutes(date, new Date());

            if (minutesUntilDate <= TIMEOUT_MINUTES) {
                createTimeout();
            }
        };

        // Sets up an interval to periodically check if the remaining time is less than or equal to 1 hour.
        const createInterval = () => {
            // Clears the previous interval (if there's any).
            removeInterval();

            // Check every hour if the number of minutes until date is <= 60.
            intervalRef.current = setInterval(
                checkIfShouldCreateTimeout,
                DATE_CHECK_INTERVAL_MINUTES * 60 * 1000
            );
        };

        // Checks if the provided date is a valid Date object.
        if (!is.date(date)) {
            return;
        }

        /**
         * If the date is in the future, we need to either set an interval or a timeout, depending
         * on how much time is left.
         */
        if (isFuture(date)) {
            const minutesUntilDate = differenceInMinutes(date, new Date());

            if (minutesUntilDate > TIMEOUT_MINUTES) {
                createInterval();
            } else {
                createTimeout();
            }
        } else if (isPast(date)) {
            runCallback();
        }

        return () => {
            // Clears the interval/timeout (if any) when the hook unmounts.
            removeInterval();
            removeTimeout();
        };
    }, [date, callback]);
};

export default useTimerUntilDate;
