import React from "react";
import Spinner from "../Spinner/Spinner";

const LoadingOverlay = () => (
    <div className="flex items-center justify-center h-screen w-screen">
        <Spinner />
    </div>
);

export default LoadingOverlay;
