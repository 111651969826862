import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import PropTypes from "prop-types";
import styled from "styled-components";
import { twMerge } from "tailwind-merge";
import { variantKinds } from "../../../constants/alertVariantKinds";
import AlertIcon from "../Icons/AlertIcon";

const AlertContainer = styled.div`
    margin: var(--spacing-3) ${(props) => props.marginRight || "var(--spacing-5)"} var(--spacing-3)
        0;

    @media screen and (max-width: 768px) {
        max-width: none;
        width: 100%;
    }
`;

const StyledAlert = styled(Alert)`
    &.MuiAlert-root {
        border-radius: var(--border-radius);
    }
    & .MuiAlert-icon {
        font-size: 21px;
    }
    & .MuiAlert-message {
        font-size: 1.1rem;
    }
`;

/**
 * Soft message for user-correctable data and information
 * @param {object} props - AlertMessage props
 * @param {string} [props.id] - the id
 * @param {string | React.ReactElement} [props.title] - optional title
 * @param {string=} [props.className] - the className
 * @param {React.ReactNode} [props.content] - alert message
 * @param {"success" | "error" | "info" | "warning"=} props.variant - determines the styling of the component (defaults to "success")
 * @param {object} [props.ContainerProps] - props for the outer div
 * @param {object} [props.AlertTitleProps] - props for the mui AlertTitle
 * @param {object} [props.AlertProps] - props for the mui Alert
 * @returns {JSX.Element} AlertMessage component
 */
const AlertMessage = ({
    id,
    title,
    content,
    className,
    ContainerProps,
    variant = variantKinds.success,
    AlertProps,
    AlertTitleProps,
}) => {
    const classNames = twMerge("max-w-max", className);

    return (
        <AlertContainer id={id} {...ContainerProps} className={classNames}>
            <StyledAlert
                className="w-full"
                severity={variant}
                iconMapping={{
                    success: <AlertIcon variant={variantKinds.success} />,
                    warning: <AlertIcon variant={variantKinds.warning} />,
                }}
                {...AlertProps}
            >
                {title && (
                    <AlertTitle sx={{ fontSize: "1.2rem" }} {...AlertTitleProps}>
                        {title}
                    </AlertTitle>
                )}
                {content}
            </StyledAlert>
        </AlertContainer>
    );
};

AlertMessage.propTypes = {
    title: PropTypes.node,
    content: PropTypes.node.isRequired,
    id: PropTypes.string,
    className: PropTypes.string,
    variant: PropTypes.oneOf([
        variantKinds.success,
        variantKinds.error,
        variantKinds.info,
        variantKinds.warning,
    ]),
    ContainerProps: PropTypes.object,
    AlertProps: PropTypes.object,
    AlertTitleProps: PropTypes.object,
};

export default AlertMessage;
