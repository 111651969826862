import React from "react";
import Collapse from "@mui/material/Collapse";
import PropTypes from "prop-types";
import { variantKinds } from "../../../constants/alertVariantKinds";
import AlertMessage from "./AlertMessage";

const TransitionAlert = (props) => {
    return (
        <Collapse in={props.open} {...props.CollapseProps}>
            <AlertMessage
                variant={props.variant}
                title={props.title}
                content={props.content}
                ContainerProps={{ style: { maxWidth: "none", width: "100%" } }}
                {...props.AlertMessageProps}
            />
        </Collapse>
    );
};

TransitionAlert.propTypes = {
    open: PropTypes.bool,
    content: PropTypes.node.isRequired,
    title: PropTypes.string,
    variant: PropTypes.oneOf([
        variantKinds.success,
        variantKinds.info,
        variantKinds.warning,
        variantKinds.error,
    ]),
    CollapseProps: PropTypes.object,
    AlertMessageProps: PropTypes.object,
};

export default TransitionAlert;
