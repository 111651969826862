import React from "react";
import { useFlagsStatus } from "@unleash/proxy-client-react";
import LoadingOverlay from "../../../components/common/LoadingOverlay/LoadingOverlay";

/**
 * @typedef FlagsCheckerProps
 * @property {React.ReactElement} children - Children.
 */

/**
 * FlagsChecker
 * @param {FlagsCheckerProps} props - FlagsChecker props.
 * @returns {React.ReactElement} - FlagsChecker component.
 */
const FlagsChecker = ({ children }) => {
    const { flagsReady } = useFlagsStatus();

    if (!flagsReady) {
        return <LoadingOverlay />;
    }

    return children;
};

export default FlagsChecker;
