import is from "@sindresorhus/is";
import log from "loglevel";
import ow from "ow";
import { assign, createMachine, send } from "xstate";
import { logEventReceived, logState } from "../../clients/XState/utils";
import { ErrorCode } from "../AccountService";
import {
    ErrorEvent as AuthenticationServiceErrorEvent,
    GoogleAuthenticationFailedEvent,
    GoogleAuthenticationPassedEvent,
    LoginEvent,
    LogoutEvent,
    RemoveHasRecentlyLoggedInEvent,
    ResetErrorsEvent,
    SignupEvent,
    ResetExpireSessionEvent,
    ExpireSessionEvent,
    ResetSessionExpiredEvent,
    SessionExpiredEvent,
} from "./events";
import states from "./states";

const MACHINE_ID = "authMachine";
const LOGIN_SERVICE_ID = "loginService";
const SIGNUP_SERVICE_ID = "signupService";
const THIRD_PARTY_AUTH_SERVICE_ID = "thirdPartyAuthService";
const VERIFY_AUTHENTICATION_SERVICE_ID = "verifyAuthService";

/**
 * Builds the transition events after authenticating. This must be called after an
 * authenticating service is called.
 * @param {function(object): boolean} transitionCondition -
 *     Determines the transition. If true, the machine transitions to the authenticated state;
 *     if false, to the unauthenticated state
 * @param {string} errorMessage - The error message
 * @returns {object} The onDone and onError XState transitions
 */
function _buildTransitionsAfterAuth(transitionCondition, errorMessage) {
    ow(transitionCondition, ow.function);
    ow(errorMessage, ow.string.nonEmpty);

    return {
        onDone: [
            // Do not change the ordering, XState runs them in-order.
            {
                cond: (_, event) => transitionCondition(event?.data),
                target: states.authenticated,
                actions: "clearError",
            },
            {
                target: states.unauthenticated,
                actions: "clearError",
            },
        ],
        onError: {
            target: states.unauthenticated,
            actions: [
                (_, event) => {
                    log.debug(errorMessage, is.nonEmptyString(event?.data) ? event.data : "");
                },
                send(
                    (_, event) =>
                        new AuthenticationServiceErrorEvent(
                            event?.data?.message || errorMessage,
                            event?.data?.code
                        )
                ),
            ],
        },
    };
}

/**
 * Builds the authentication machine definition
 * @param {AccountService} accountService - The account service
 * @returns {object} The authentication machine definition
 */
const authMachine = (accountService) => {
    return createMachine(
        {
            id: MACHINE_ID,
            initial: states.validatingAuthentication,
            predictableActionArguments: true,
            context: {
                hasError: false,
                errorCode: null,
                hasRecentlyLoggedIn: false,
                shouldExpireSession: false,
                isSessionExpired: false,
            },
            on: {
                [ResetErrorsEvent.eventType]: {
                    actions: "clearError",
                },
                [AuthenticationServiceErrorEvent.eventType]: {
                    actions: "handleError",
                },
                [ResetExpireSessionEvent.eventType]: {
                    actions: ["logEventReceived", assign({ shouldExpireSession: false })],
                },
                [ExpireSessionEvent.eventType]: {
                    actions: ["logEventReceived", assign({ shouldExpireSession: true })],
                    target: states.unauthenticated,
                },
                [ResetSessionExpiredEvent.eventType]: {
                    actions: ["logEventReceived", assign({ isSessionExpired: false })],
                },
                [SessionExpiredEvent.eventType]: {
                    actions: ["logEventReceived", assign({ isSessionExpired: true })],
                },
            },
            states: {
                [states.validatingAuthentication]: {
                    entry: logState(MACHINE_ID, states.validatingAuthentication),
                    invoke: {
                        id: VERIFY_AUTHENTICATION_SERVICE_ID,
                        src: "verifyAuthentication",
                        ..._buildTransitionsAfterAuth(
                            (response) => is.boolean(response) && response,
                            "Error validating authentication."
                        ),
                    },
                },
                [states.unauthenticated]: {
                    entry: [
                        logState(MACHINE_ID, states.unauthenticated),
                        assign({ hasRecentlyLoggedIn: false }),
                        /*
                         * Clearing the account details should only happen in the unauthenticated state
                         * to avoid unexpected behavior if the details are cleared while authenticated
                         */
                        "logout",
                    ],
                    on: {
                        [SignupEvent.eventType]: states.signingUp,
                        [LoginEvent.eventType]: states.loggingIn,
                        [GoogleAuthenticationPassedEvent.eventType]: states.thirdPartyAuthenticate,
                        [GoogleAuthenticationFailedEvent.eventType]: {
                            actions: send(new AuthenticationServiceErrorEvent()),
                        },
                    },
                },
                [states.thirdPartyAuthenticate]: {
                    entry: [
                        logState(MACHINE_ID, states.thirdPartyAuthenticate),
                        assign({ hasRecentlyLoggedIn: true }),
                    ],
                    invoke: {
                        id: THIRD_PARTY_AUTH_SERVICE_ID,
                        src: "thirdPartyAuth",
                        ..._buildTransitionsAfterAuth(
                            (response) =>
                                is.nonEmptyObject(response) &&
                                is.number(response.accountId) &&
                                is.nonEmptyString(response.authToken),
                            "Error authenticating with third party."
                        ),
                    },
                },
                [states.signingUp]: {
                    entry: [
                        logState(MACHINE_ID, states.signingUp),
                        assign({ hasRecentlyLoggedIn: true }),
                    ],
                    invoke: {
                        id: SIGNUP_SERVICE_ID,
                        src: "signup",
                        ..._buildTransitionsAfterAuth(
                            (response) =>
                                is.nonEmptyObject(response) &&
                                is.number(response.accountId) &&
                                is.nonEmptyString(response.authToken) &&
                                is.nonEmptyString(response.loginId),
                            "Error signing up."
                        ),
                    },
                },
                [states.loggingIn]: {
                    entry: [
                        logState(MACHINE_ID, states.loggingIn),
                        assign({ hasRecentlyLoggedIn: true }),
                    ],
                    invoke: {
                        id: LOGIN_SERVICE_ID,
                        src: "login",
                        ..._buildTransitionsAfterAuth((response) => {
                            // only validates the required data
                            return (
                                is.nonEmptyObject(response) &&
                                is.number(response.accountId) &&
                                is.nonEmptyString(response.authToken) &&
                                is.boolean(response.isAdmin)
                            );
                        }, "Error logging in."),
                    },
                },
                [states.authenticated]: {
                    entry: [logState(MACHINE_ID, states.authenticated)],
                    on: {
                        [LogoutEvent.eventType]: {
                            target: states.unauthenticated,
                            actions: "clearError",
                        },
                        [RemoveHasRecentlyLoggedInEvent.eventType]: {
                            actions: assign({ hasRecentlyLoggedIn: false }),
                        },
                    },
                },
            },
        },
        {
            services: {
                verifyAuthentication: () => accountService.isLoggedInAsync(),
                signup: (_, event) => {
                    return accountService.signupAsync(
                        event.firstName,
                        event.lastName,
                        event.email,
                        event.password,
                        event.preferredLanguageCode,
                        event.referrer
                    );
                },
                login: (_, event) => {
                    return accountService.loginAsync(event.email, event.password);
                },
                thirdPartyAuth: (_, event) => {
                    return accountService.thirdPartyAuthenticateAsync(
                        event.tokenId,
                        event.preferredLanguageCode,
                        event.referrer
                    );
                },
            },
            actions: {
                logState: (context, event, actionMeta) => {
                    const state = actionMeta?.state?.value;
                    log.debug(`${MACHINE_ID} is in state ${state}`);
                },

                logout: () => accountService.logout(),

                handleError: assign({
                    hasError: true,
                    errorCode: (_, event) => event.code,
                    shouldExpireSession: (_, event) => event.code === ErrorCode.Unauthenticated,
                }),

                clearError: assign({
                    hasError: false,
                    errorCode: null,
                }),
                logEventReceived: logEventReceived(MACHINE_ID),
            },
        }
    );
};

export default authMachine;
