import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import strings from "../../../constants/strings";

/**
 * @typedef UseGetVerificationTokenQueryStringResult
 * @property {string} isLoading - Whether we're reading the email verification token from the URL query params.
 * @property {string} token - Email verification token.
 */

/**
 * useGetVerificationTokenQueryString - Retrieves the email verification token from the URL params and removes it.
 * @returns {UseGetVerificationTokenQueryStringResult} - Get email verification token result.
 */
const useGetVerificationTokenQueryString = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [token, setToken] = useState(null);

    const [urlParams, setUrlParams] = useSearchParams(strings.emailVerificationToken);

    useEffect(() => {
        if (urlParams.has(strings.emailVerificationToken)) {
            const token = urlParams.get(strings.emailVerificationToken);
            setToken(token);
            urlParams.delete(strings.emailVerificationToken);
            setUrlParams(urlParams);
        }
        setIsLoading(false);
    }, [urlParams, setUrlParams]);

    return { isLoading, token };
};

export default useGetVerificationTokenQueryString;
