import is from "@sindresorhus/is";

export const variantKinds = {
    success: "success",
    error: "error",
    info: "info",
    warning: "warning",
};

// Map that associates a variant with a color
export const variantColorMap = {
    [variantKinds.success]: "var(--color-success-mid)",
    [variantKinds.error]: "var(--color-error-mid)",
    [variantKinds.info]: "var(--color-info-mid)",
    [variantKinds.warning]: "var(--color-warning-mid)",
};

/**
 * Determines the color based off of variant
 * @param {"success" | "error" | "info" | "warning"=} variant - determines the color (defaults to "success")
 * @returns {string} color string
 */
export const getVariantColor = (variant) => {
    let color = variantColorMap[variant];

    return is.nullOrUndefined(color) ? variantColorMap[variantKinds.success] : color;
};
