import { useEffect, useState } from "react";
import ow from "ow";

/**
 * Hook to append an external JS file to a component
 * @param {string} url - URL of the script to append
 * @param {Array} dependencies - dependencies array
 * @returns {boolean} - Returns true if the script was loaded successfully
 */
const useScript = (url, dependencies) => {
    ow(url, ow.string.nonEmpty);

    const [scriptLoadedSuccessfully, setScriptLoadedSuccessfully] = useState(false);

    useEffect(() => {
        const script = document.createElement("script");

        script.src = url;
        script.async = true;
        script.defer = true;
        script.onload = () => setScriptLoadedSuccessfully(true);
        script.onerror = () => setScriptLoadedSuccessfully(false);
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);

    return scriptLoadedSuccessfully;
};

export default useScript;
