import { UnleashClient } from "unleash-proxy-client";
import { defaultFlagConfig } from "../../provider/featureFlags/featureFlagConfig";

/**
 * UnleashService for stateless, single instance of UnleashClient
 * In most cases, you should use the useFlag hook instead of this service, but this service is useful where hooks cannot be used
 */
class UnleashService {
    private static unleashClient: UnleashClient | null = null;

    static start() {
        if (!this.unleashClient) {
            this.unleashClient = new UnleashClient({
                ...defaultFlagConfig,
                url: defaultFlagConfig.url ?? "",
                clientKey: defaultFlagConfig.clientKey ?? "",
                appName: defaultFlagConfig.appName,
                refreshInterval: defaultFlagConfig.refreshInterval ?? 15,
                environment: defaultFlagConfig.environment ?? "",
            });
            this.unleashClient.start();
        }
    }

    static getClient() {
        if (!this.unleashClient) {
            throw new Error("UnleashService: UnleashClient has not been started");
        }

        return this.unleashClient;
    }

    static isFeatureEnabled(featureName: string): boolean {
        const unleashClient = UnleashService.getClient();
        return unleashClient.isEnabled(featureName);
    }
}

export default UnleashService;
