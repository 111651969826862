import React, { useState, useEffect, useMemo, useCallback } from "react";
import strings from "../../constants/strings";
import EmailVerificationContext from "./EmailVerificationContext";
import useGetVerificationTokenQueryString from "./hooks/useGetVerificationTokenQueryString";

/**
 * @typedef EmailVerificationProviderProps
 * @property {React.ReactElement} children - Children.
 */

/**
 * EmailVerificationProvider
 * @param {EmailVerificationProviderProps} props - EmailVerificationProvider props.
 * @returns {React.ReactElement} - EmailVerificationProvider component.
 */
const EmailVerificationProvider = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [wasVerificationTokenFound, setWasVerificationTokenFound] = useState(false);
    const [verificationToken, setVerificationToken] = useState(null);

    const { isLoading: isLoadingEmailVerificationToken, token: verificationTokenQS } =
        useGetVerificationTokenQueryString();

    const clearVerificationToken = useCallback(() => {
        setVerificationToken(null);
        sessionStorage.removeItem(strings.emailVerificationToken);
    }, []);

    const clearWasVerificationTokenFound = useCallback(() => {
        setWasVerificationTokenFound(false);
    }, []);

    useEffect(() => {
        if (!isLoadingEmailVerificationToken) {
            if (verificationTokenQS) {
                setVerificationToken(verificationTokenQS);
                setWasVerificationTokenFound(true);
                sessionStorage.setItem(strings.emailVerificationToken, verificationTokenQS);
            } else {
                const sessionStorageVerificationToken = sessionStorage.getItem(
                    strings.emailVerificationToken
                );
                if (sessionStorageVerificationToken) {
                    setVerificationToken(sessionStorageVerificationToken);
                }
            }
            setIsLoading(false);
        }
    }, [isLoadingEmailVerificationToken, verificationTokenQS]);

    const value = useMemo(
        () => ({
            isLoading,
            wasVerificationTokenFound,
            clearWasVerificationTokenFound,
            verificationToken,
            clearVerificationToken,
        }),
        [
            clearVerificationToken,
            clearWasVerificationTokenFound,
            isLoading,
            verificationToken,
            wasVerificationTokenFound,
        ]
    );

    return (
        <EmailVerificationContext.Provider value={value}>
            {children}
        </EmailVerificationContext.Provider>
    );
};

export default EmailVerificationProvider;
