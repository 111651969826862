import XStateEvent from "../../../clients/XState/Models/XStateEvent";

/**
 * Models an event describing a request to expire the user's session.
 * Expiring the user's session consists in erasing authenticated data
 * as well as displaying the correct errors.
 */
class ExpireSessionEvent extends XStateEvent {
    static eventType = "AUTHENTICATION.EXPIRE_SESSION";

    /**
     * Constructs the event
     */
    constructor() {
        super(ExpireSessionEvent.eventType);
    }
}

export default ExpireSessionEvent;
