import is from "@sindresorhus/is";
import { CourtFormKind } from "../../models/courtFormKind";
import TrialPresentationRecipientKind from "../../models/trialPresentationRecipientKind";
import { documentKinds } from "./constants";

/** @typedef {import("../../models/trialPresentationRecipientKind").TrialPresentationRecipientKindType} TrialPresentationRecipientKindType */

/**
 * Maps a document kind from a court form kind
 * @param {CourtFormKind} courtFormKind - The court form kind
 * @returns {documentKinds|null} A document kind, or null if a document kind wasn't found for the given court form kind
 */
const mapDocumentKindFromCourtFormKind = (courtFormKind) => {
    if (!is.nonEmptyStringAndNotWhitespace(courtFormKind)) {
        return null;
    }

    switch (courtFormKind) {
        case CourtFormKind.Sc100:
            return documentKinds.SC100;
        case CourtFormKind.Sc100A:
            return documentKinds.SC100A;
        case CourtFormKind.Sc103:
            return documentKinds.SC103;
        default:
            return null;
    }
};

/**
 * Maps a document kind from a trial presentation recipient kind
 * @param {TrialPresentationRecipientKindType} recipientKind - The trial presentation recipient
 * @returns {documentKinds|null} A document kind, or null if a document kind wasn't found for the given recipient kind
 */
const mapDocumentKindFromTrialPresentationRecipientKind = (recipientKind) => {
    if (!is.nonEmptyStringAndNotWhitespace(recipientKind)) {
        return null;
    }

    switch (recipientKind) {
        case TrialPresentationRecipientKind.Plaintiff:
            return documentKinds.TRIAL_PRESENTATION_PLAINTIFF;
        case TrialPresentationRecipientKind.Defendant:
            return documentKinds.TRIAL_PRESENTATION_DEFENDANT;
        case TrialPresentationRecipientKind.Judge:
            return documentKinds.TRIAL_PRESENTATION_JUDGE;
        default:
            return null;
    }
};

export { mapDocumentKindFromCourtFormKind, mapDocumentKindFromTrialPresentationRecipientKind };
