import useMediaQuery from "@mui/material/useMediaQuery";

const useScreenSize = () => {
    // Breakpoint and up queries
    const isExtraSmallOrUpScreen = useMediaQuery("(min-width: 480px)");
    const isSmallOrUpScreen = useMediaQuery("(min-width: 768px)");
    const isMediumOrUpScreen = useMediaQuery("(min-width: 1000px)");
    const isLargeOrUpScreen = useMediaQuery("(min-width: 1200px)");

    return {
        isExtraSmallOrUpScreen,
        isSmallOrUpScreen,
        isMediumOrUpScreen,
        isLargeOrUpScreen,
    };
};

export default useScreenSize;
