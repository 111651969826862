import { createContext } from "react";

const noOp = () => {};

/**
 * @typedef AuthSessionContext
 * @property {boolean} shouldExpireSession - Whether or not the user's session should expire.
 * @property {boolean} isSessionExpired - Whether or not the user's session has expired.
 * @property {() => void} resetExpireSession - Resets the flag that indicates that the user's session should expire.
 * @property {() => void} onExpireSession - Indicates the user's session should expire.
 * @property {() => void} resetSessionExpired - Resets the flag that indicates that the user's session expired.
 * @property {() => void} onSessionExpired - Expires the user's session.
 */

/** @type {AuthSessionContext} */
const initialContext = {
    shouldExpireSession: null,
    isSessionExpired: null,
    resetExpireSession: noOp,
    onExpireSession: noOp,
    resetSessionExpired: noOp,
    onSessionExpired: noOp,
};

const AuthSessionContext = createContext(initialContext);

export default AuthSessionContext;
