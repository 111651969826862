const localStorageKeys = {
    caseMachine: "case-machine",
    caseBlockedNodata: "case-blocked-no-data",
    primaryMachine: "primary-machine",
    casesData: "cases-data",
    casesEtags: "cases-etags",
    purchaseOrders: "purchase-orders",
    caseSession: "case-session",
    pagesMetadata: "pages-metadata",
    settlementLetterPurchases: "settlement-letter-purchases",
    chunkLoadFailureDataKey: "chunk-load-recovery",
};

export default localStorageKeys;
