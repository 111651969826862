import React from "react";
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { muiTheme } from "../../styles/materialUiTheme";

/**
 * @typedef ThemeProviderProps
 * @property {React.ReactElement} children - Children.
 */

/**
 * ThemeProvider is required as of Mui5 https://mui.com/material-ui/migration/migration-v4/#set-up-themeprovider
 * @param {ThemeProviderProps} props - ThemeProvider props.
 * @returns {React.ReactElement} - ThemeProvider component.
 */
const ThemeProvider = ({ children }) => {
    return (
        <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={muiTheme}>{children}</MuiThemeProvider>
        </StyledEngineProvider>
    );
};

export default ThemeProvider;
