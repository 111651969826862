// TODO: MVP-7140 Fix legacy jsdoc/require-property-description rule violations
/* eslint-disable jsdoc/require-property-description */
/**
 * @typedef {"h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "body1" | "body2" | "body3" | "subtitle1" | "caption"} FontSizeEnumType
 * @typedef FontWeightVariantsType
 * @property {string} light
 * @property {string} normal
 * @property {string} semibold
 * @property {string} bold
 * @typedef FontSizeVariantsType
 * @property {"h1"} h1
 * @property {"h2"} h2
 * @property {"h3"} h3
 * @property {"h4"} h4
 * @property {"h5"} h5
 * @property {"h6"} h6
 * @property {"body1"} body1
 * @property {"body2"} body2
 * @property {"body3"} body3
 * @property {"subtitle1"} subtitle1
 * @property {"caption"} caption
 */

/** @type {FontWeightVariantsType} */
const FONT_WEIGHT_VARIANTS = {
    light: window.getComputedStyle(document.body).getPropertyValue("--font-weight-light"),
    normal: window.getComputedStyle(document.body).getPropertyValue("--font-weight-medium"),
    semibold: window.getComputedStyle(document.body).getPropertyValue("--font-weight-semi-bold"),
    bold: window.getComputedStyle(document.body).getPropertyValue("--font-weight-bold"),
};

/** @type {FontSizeVariantsType} */
const FONT_SIZE_VARIANTS = {
    h1: "h1",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    h5: "h5",
    h6: "h6",
    body1: "body1",
    body2: "body2",
    body3: "body3",
    subtitle1: "subtitle1",
    caption: "caption",
};

export { FONT_WEIGHT_VARIANTS, FONT_SIZE_VARIANTS };
