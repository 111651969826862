import { createContext } from "react";

const noOp = () => {};

/**
 * @typedef DrawerContext
 * @property {(id: string, content: React.ReactElement | React.ReactElement[]) => void} setDrawer - Sets drawer content.
 * @property {() => void} close - Closes drawer.
 */

/** @type {DrawerContext} */
const initialContext = {
    setDrawer: noOp,
    close: noOp,
};

const DrawerContext = createContext(initialContext);

export default DrawerContext;
