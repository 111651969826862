import React from "react";
import { QueryClient, QueryClientProvider as ReactQueryClientProvider } from "react-query";

const queryClient = new QueryClient();

/**
 * @typedef QueryClientProviderProps
 * @property {React.ReactElement} children - Children.
 */

/**
 * QueryClientProvider
 * @param {QueryClientProviderProps} props - QueryClientProvider props.
 * @returns {React.ReactElement} - QueryClientProvider component.
 */
const QueryClientProvider = ({ children }) => {
    return <ReactQueryClientProvider client={queryClient}>{children}</ReactQueryClientProvider>;
};

export default QueryClientProvider;
