import { createContext } from "react";

/**
 * @typedef RouteContextType
 * @property {string} currentPath - The current path of the router.
 * @property {string} prevPath - The previous path of the router.
 */

/** @type {RouteContextType} */
const initialContext = {
    currentPath: "",
    prevPath: "",
};

const RouteContext = createContext(initialContext);

export default RouteContext;
