import XStateEvent from "../../../clients/XState/Models/XStateEvent";

/**
 * Models an event describing a request to reset the flag that indicates
 * that the user's session should expire.
 */
class ResetExpireSessionEvent extends XStateEvent {
    static eventType = "AUTHENTICATION.RESET_EXPIRE_SESSION";

    /**
     * Constructs the event
     */
    constructor() {
        super(ResetExpireSessionEvent.eventType);
    }
}

export default ResetExpireSessionEvent;
