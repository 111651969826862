import GrpcStatusCodes from "../../../grpc/GrpcStatusCodes";
import authSessionEventListener from "../../../provider/authSession/authSessionEventListener";

const InterceptedStream = function (stream) {
    this.stream = stream;
};

InterceptedStream.prototype.on = function (eventType, callback) {
    if (eventType == "error") {
        const newCallback = (err) => {
            if (err.code === GrpcStatusCodes.statusCodes.unauthenticated) {
                authSessionEventListener.expireSession();
            }

            callback(err);
        };
        this.stream.on(eventType, newCallback);
    } else {
        this.stream.on(eventType, callback);
    }
    return this;
};

InterceptedStream.prototype.cancel = function () {
    this.stream.cancel();
    return this;
};

const StreamInterceptor = function () {};

StreamInterceptor.prototype.intercept = function (request, invoker) {
    return new InterceptedStream(invoker(request));
};

export default StreamInterceptor;
