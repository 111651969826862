import questUIStrings from "../../constants/strings";

const defaultFlagConfig = {
    url: process.env.REACT_APP_UNLEASH_API_URL,
    clientKey: process.env.REACT_APP_UNLEASH_API_TOKEN,
    // Default value suggested in Unleash docs
    refreshInterval: 15,
    // The proxy SDK will append this to the context fields used to evaluate a feature toggle
    // We can use this in combination with strategy constraints to control the exposure of a feature toggle
    appName: questUIStrings.appName,
    environment: process.env.REACT_APP_ENVIRONMENT,
};

export { defaultFlagConfig };
