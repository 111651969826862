// Status code data gathered from https://github.com/grpc/grpc/blob/master/doc/statuscodes.md
const statusCodes = {
    ok: 0,
    cancelled: 1,
    unknown: 2,
    invalidArgument: 3,
    deadlineExceeded: 4,
    notFound: 5,
    alreadyExists: 6,
    permissionDenied: 7,
    resourceExhausted: 8,
    failedPrecondition: 9,
    aborted: 10,
    outOfRange: 11,
    unimplemented: 12,
    internal: 13,
    unavailable: 14,
    dataLoss: 15,
    unauthenticated: 16,
};

const nonTransientErrorCodes = [
    statusCodes.invalidArgument,
    statusCodes.notFound,
    statusCodes.alreadyExists,
    statusCodes.permissionDenied,
    statusCodes.failedPrecondition,
    statusCodes.outOfRange,
    statusCodes.unimplemented,
    statusCodes.dataLoss,
    statusCodes.unauthenticated,
];

const transientErrorCodes = [
    statusCodes.cancelled,
    statusCodes.unknown,
    statusCodes.deadlineExceeded,
    statusCodes.resourceExhausted,
    statusCodes.aborted,
    statusCodes.internal,
    statusCodes.unavailable,
];

export default {
    statusCodes,
    nonTransientErrorCodes,
    transientErrorCodes,
};
