const events = {
    article: "article",
};

const useBeacon = () => {
    const openSidebarArticle =
        (articleId, type = "sidebar") =>
        () => {
            window.Beacon(events.article, articleId, { type: type });
        };

    const suggestArticle = (context) => {
        if (context.isLoggedIn && window.Beacon !== undefined) {
            return window.Beacon("suggest");
        }
    };

    return {
        openSidebarArticle,
        suggestArticle,
    };
};

export default useBeacon;
