const TrialPresentationRecipientKind = {
    Unknown: "unknown",
    Plaintiff: "plaintiff",
    Defendant: "defendant",
    Judge: "judge",
} as const;

export type TrialPresentationRecipientKindType =
    typeof TrialPresentationRecipientKind[keyof typeof TrialPresentationRecipientKind];

export default TrialPresentationRecipientKind;
