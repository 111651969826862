import { useContext } from "react";
import ow from "ow";
import useBeacon from "../../../hooks/useBeacon";
import AuthContext from "../AuthContext";

/** @typedef {import("../AuthContext").AuthContext} AuthContext */

/**
 * useAuth - Exposes the Authentication context.
 * @returns {AuthContext} - Authentication context.
 */
const useAuth = () => {
    const { suggestArticle } = useBeacon();

    const context = useContext(AuthContext);

    suggestArticle(context);
    ow(context, ow.object);

    return context;
};

export default useAuth;
