import React from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import useScreenSize from "../../../hooks/useScreenSize";
import Typography from "../Typography";

const DialogTitle = (props) => {
    const { isExtraSmallOrUpScreen } = useScreenSize();

    return (
        <Modal.Title>
            <Typography
                className="text-grey-900"
                variant={isExtraSmallOrUpScreen ? "h4" : "h5"}
                fontWeight="bold"
            >
                {props.children}
            </Typography>
        </Modal.Title>
    );
};

DialogTitle.propTypes = {
    children: PropTypes.node,
};

export default DialogTitle;
