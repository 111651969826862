import React from "react";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import PropTypes from "prop-types";
import { variantColorMap, variantKinds } from "../../../constants/alertVariantKinds";

const DEFAULT_FONT_SIZE = "inherit";
const DEFAULT_COLOR = variantColorMap[variantKinds.success];

const AlertIcon = ({ variant = variantKinds.success, fontSize = DEFAULT_FONT_SIZE, IconProps }) => {
    const color = variantColorMap[variant];

    switch (variant) {
        case variantKinds.error:
            return <ErrorOutline style={{ fontSize, color }} {...IconProps} />;
        case variantKinds.info:
            return <InfoOutlined style={{ fontSize, color }} {...IconProps} />;
        case variantKinds.warning:
            return <ErrorOutline style={{ fontSize, color }} {...IconProps} />;
        default:
            // no valid variant or success
            return (
                <CheckCircleOutlineOutlinedIcon
                    style={{ fontSize, color: DEFAULT_COLOR }}
                    {...IconProps}
                />
            );
    }
};

AlertIcon.propTypes = {
    variant: PropTypes.oneOf([
        variantKinds.success,
        variantKinds.error,
        variantKinds.info,
        variantKinds.warning,
    ]),
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    IconProps: PropTypes.object,
};

export default AlertIcon;
