import is from "@sindresorhus/is";
import Cookies from "js-cookie";
import log from "loglevel";
import ow from "ow";
import questUIStrings from "../../constants/strings";

interface CookieManager {
    getAccountDetails: () => AccountDetails | null;
    updateAccountDetails: (accountDetails: AccountDetails) => void;
    getRootDomain: () => string;
    removeAccountDetails: () => void;
}

export type { CookieManager };

interface AccountDetails {
    accountId?: number | null;
    authToken?: string | null;
    email?: string | null;
    firstName?: string | null;
    middleName?: string | null;
    lastName?: string | null;
    birthDate?: string | null;
    phoneNumber?: string | null;
    preferredLanguageCode?: string | null;
    isThirdPartyAuth?: boolean | null;
    isEmailVerified?: boolean | null;
}

export type { AccountDetails };

interface SecureCookie {
    secure: boolean;
    sameSite: "strict" | "Strict" | "lax" | "Lax" | "none" | "None";
    domain: string;
}

/**
 * Gets an object of cookie attributes.
 * @returns - Secure cookie object
 * @private
 */
function _getCookieAttributes(): SecureCookie {
    /*
     * We default to secure cookies, which enforces that cookies may only be set when using
     * a TLS connection. This is so that cookies will not be exposed in plain text when
     * exchanging requests over the wire. However, there is one exception to the default rule,
     * that exception is when we are running in development mode *and* talking to a loopback
     * address (i.e. localhost, 127.0.0.1, [::1]). In this case we need to use insecure
     * cookies in order for authentication to work within the confines of its normal mechanics.
     * Properly functioning browsers ignore set-cookie when using non-TLS and secure cookies.
     * As such our auth mechanism cannot set the cookie necessary to mark a user as authenticated
     * and the developer cannot login.
     *
     * We check the NODE_ENV environment variable for the value "development" because that
     * can only be set at build time and would take decisive and intentional steps to manipulate
     * otherwise. Furthermore we also check to make sure the connection is to a loopback address.
     * This guards against someone building the application in development mode and deploying it
     * to a remote server.
     *
     * The code to check if we should be using insecure cookies is intentionally verbose and
     * explicit so that future readers can trivially recognize it. *It should not be simplified!*
     */
    let useSecureCookies = true;
    if (process.env.NODE_ENV === "development") {
        useSecureCookies = false;
    }

    return {
        secure: useSecureCookies,
        sameSite: "lax",
        // Set the cookie on the root domain (e.g. "q4justice.dev")
        // so subdomains (e.g. "local.q4justice.dev", "portal.q4justice.dev") can read it
        domain: getRootDomain(),
    };
}

const getRootDomain = () => {
    const hostPieces = window.location.hostname.split(".").reverse();

    return hostPieces.length === 1 ? hostPieces[0] : `${hostPieces[1]}.${hostPieces[0]}`;
};

function _setAccountDetails(accountDetails: AccountDetails) {
    ow(
        accountDetails,
        ow.object.exactShape({
            email: ow.string,
            authToken: ow.string,
            accountId: ow.number,
            firstName: ow.any(ow.string, ow.nullOrUndefined),
            middleName: ow.any(ow.string, ow.nullOrUndefined),
            lastName: ow.any(ow.string, ow.nullOrUndefined),
            birthDate: ow.any(ow.string, ow.nullOrUndefined),
            phoneNumber: ow.any(ow.string, ow.nullOrUndefined),
            preferredLanguageCode: ow.any(ow.string, ow.nullOrUndefined),
            isThirdPartyAuth: ow.any(ow.boolean),
            isEmailVerified: ow.any(ow.boolean, ow.nullOrUndefined),
        })
    );
    Cookies.set(
        questUIStrings.authCookieStorageKey,
        JSON.stringify(accountDetails),
        _getCookieAttributes()
    );
}

function _getAccountDetails(): AccountDetails | null {
    const authCookie = Cookies.get(questUIStrings.authCookieStorageKey);
    if (is.nullOrUndefined(authCookie)) {
        return null;
    }

    try {
        return JSON.parse(authCookie);
    } catch (e) {
        log.error(`Unable to parse authCookie ${authCookie}: ${e}`);
        return null;
    }
}

/**
 * Removes the authCookie which contains Account details
 */
const removeAccountDetails = () => {
    Cookies.remove(questUIStrings.authCookieStorageKey, _getCookieAttributes());
};

export default {
    /**
     * Gets the account details
     * @returns {AccountDetails | null} accountDetails
     */
    getAccountDetails: _getAccountDetails,
    /**
     * Updates the account details
     * @param accountDetails - The account details
     */
    updateAccountDetails: function (accountDetails: AccountDetails) {
        ow(
            accountDetails,
            ow.object.exactShape({
                email: ow.any(ow.string, ow.nullOrUndefined),
                authToken: ow.any(ow.string, ow.nullOrUndefined),
                accountId: ow.any(ow.number, ow.nullOrUndefined),
                firstName: ow.any(ow.string, ow.nullOrUndefined),
                middleName: ow.any(ow.string, ow.nullOrUndefined),
                lastName: ow.any(ow.string, ow.nullOrUndefined),
                birthDate: ow.any(ow.string, ow.nullOrUndefined),
                phoneNumber: ow.any(ow.string, ow.nullOrUndefined),
                preferredLanguageCode: ow.any(ow.string, ow.nullOrUndefined),
                isThirdPartyAuth: ow.any(ow.boolean, ow.nullOrUndefined),
                isEmailVerified: ow.any(ow.boolean, ow.nullOrUndefined),
            })
        );

        const _accountDetails = _getAccountDetails();

        _setAccountDetails({
            ..._accountDetails,
            ...accountDetails,
        });
    },
    removeAccountDetails,
    getRootDomain,
};
