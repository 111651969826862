import React from "react";
import { LocalizationProvider as DateLocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useTranslation } from "react-i18next";
import supportedLanguages from "../../constants/supportedLanguages";

/**
 * @typedef LocalizationProviderProps
 * @property {React.ReactElement} children - Children.
 */

/**
 * LocalizationProvider
 * @param {LocalizationProviderProps} props - LocalizationProvider props.
 * @returns {React.ReactElement} - LocalizationProvider component.
 */
const LocalizationProvider = ({ children }) => {
    const { i18n } = useTranslation();

    return (
        <DateLocalizationProvider
            adapterLocale={supportedLanguages[i18n.language]}
            dateAdapter={AdapterDateFns}
        >
            {children}
        </DateLocalizationProvider>
    );
};

export default LocalizationProvider;
