/**
 * A library of common utility functions and types for the XState state machine
 * package
 *
 * The parameters of most of these functions come from the XState API,
 * e.g., https://xstate.js.org/docs/guides/actions.html#declarative-actions
 */
import log from "loglevel";

/** @typedef {import("xstate").StateMachine} StateMachine */
/** @typedef {import("xstate").EventObject} EventObject */
/** @typedef {import("xstate").ActionObject} ActionObject */
/** @typedef {import("xstate").InvokeCallback} InvokeCallback */

/**
 * @callback CallbackHandler https://xstate.js.org/docs/guides/communication.html#invoking-callbacks
 * @param {Function} callback - The callback that takes the event to be sent
 * @param {Function} onEvent - The listener that listens to an event from the parent
 * @returns {Function | void} Optional function that performs a cleanup
 */

/**
 * @template {object} TContext
 * @template {EventObject} TEvent
 * @typedef {import("xstate").AssignAction<TContext, TEvent>} AssignAction
 */

/**
 * Builds an XState action that logs the event that was received
 * @param {string} machineId - Identifies the machine
 * @returns {ActionObject} An XState action that logs the current state
 */
export const logEventReceived = (machineId) => {
    return (_, event) => {
        const { type, ...rest } = event || {};
        log.debug(`${machineId} received ${type}`, { ...rest });
    };
};

/**
 * Builds an XState action that logs the current state of a given machine
 * @param {string} machineId - Identifies the machine
 * @param {string} state - The state of the machine
 * @returns {ActionObject} An XState action that logs the current state
 */
export const logState = (machineId, state) => {
    return () => {
        log.debug(`${machineId} is in state ${state}`);
    };
};
