const questUIStrings = {
    appName: "QuestUI",
    bearer: "Bearer",
    authCookieStorageKey: "authCookie",
    httpHeaders: {
        applicationJson: "application/json",
        authorization: "Authorization",
        contentType: "Content-Type",
        formUrlEncoded: "application/x-www-form-urlencoded",
    },
    keyCodes: {
        enter: 13,
    },
    urlParams: {
        name: "urlParam",
        paymentSuccess: "paymentSuccess",
        referrer: "referrer",
    },
    demandLettersFileName: "DemandLetters",
    demandLetterFileName: "DemandLetter",
    supportEmail: "support@justicedirect.com",
    errorEmailSubject: "Report a Bug",
    emailVerificationToken: "email_verification_token",
    silverDragonAttorneyServicesEmail: "silverdragonattysvc@gmail.com",
    courtFormsFileName: "CourtForms",
    additionalFormsFileName: "AdditionalForms",
    approvedVendorName: "Silver Dragon Attorney Services",
    SOPCoversheetName: "JusticeDirect_Service_of_Process_Coversheet.pdf",
    fileAndServeInstructionsFileName: "FileAndServeInstructions.pdf",
    trialPresentationsFileName: "TrialPresentations.zip",
    plaintiffTrialPresentationFileName: "JDTrialPresentation_Plaintiff.pdf",
    defendantTrialPresentationFileName: "JDTrialPresentation_Defendant.pdf",
    judgeTrialPresentationFileName: "JDTrialPresentation_Judge.pdf",
    courtPrepInfoPacketFileName: "CourtPrepInfoPacket.pdf",
    settlementLetterFileName: "SettlementLetter",
    settlementLettersFileName: "SettlementLetters.zip",
    peopleClerk: "peopleclerk",
} as const;

export default questUIStrings;
