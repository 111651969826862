import { createContext } from "react";
import { type UpdateAccountDetails } from "../types";

interface UpdateNameContext {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    updateName: (firstName: string, lastName: string) => Promise<UpdateAccountDetails>;
    clearUpdateNameState: () => void;
}

const initialContext: UpdateNameContext = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    updateName: () => Promise.reject(),
    clearUpdateNameState: () => {},
};

const AccountContext = createContext(initialContext);

export default AccountContext;
