import XStateEvent from "../../../clients/XState/Models/XStateEvent";

/**
 * Models an event describing that a Google authentication failed
 */
class GoogleAuthenticationFailedEvent extends XStateEvent {
    static eventType = "AUTHENTICATION.GOOGLE_AUTHENTICATION_FAILED";

    /**
     * Constructs the event
     */
    constructor() {
        super(GoogleAuthenticationFailedEvent.eventType);
    }
}

export default GoogleAuthenticationFailedEvent;
