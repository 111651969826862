import { trackPromise } from "react-promise-tracker";
import { useQuery, type UseQueryOptions } from "react-query";
import AccountService from "../AccountService";
import AccountServiceError from "../AccountServiceError";
import type { GetAccountResponse } from "../types";

type UseGetAccountQueryOptions = UseQueryOptions<
    GetAccountResponse,
    AccountServiceError,
    GetAccountResponse,
    string[]
>;

const defaultConfig: UseGetAccountQueryOptions = {
    enabled: true,
    refetchInterval: false,
};

interface AccountQueryData {
    isIdle: boolean;
    isFetching: boolean;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    account?: GetAccountResponse;
}

const useGetAccount = (config: Partial<UseGetAccountQueryOptions> = {}): AccountQueryData => {
    const { enabled, refetchInterval } = { ...defaultConfig, ...config };

    const { data, isIdle, isFetching, isLoading, isSuccess, isError } = useQuery<
        GetAccountResponse,
        AccountServiceError,
        GetAccountResponse,
        string[]
    >(["getAccount"], () => trackPromise(AccountService.getAccountAsync()), {
        enabled,
        refetchInterval,
        cacheTime: 0,
        staleTime: 0,
        retry: false,
    });

    return {
        account: data,
        isIdle,
        isFetching,
        isLoading,
        isSuccess,
        isError,
    };
};

export default useGetAccount;
