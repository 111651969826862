import React, { useState } from "react";
import DrawerBase from "../../components/common/Drawer/DrawerBase";
import DrawerContext from "./DrawerContext";

/**
 * @typedef DrawerProviderProps
 * @property {React.ReactElement} children - Children.
 */

/**
 * DrawerProvider
 * @param {DrawerProviderProps} props - DrawerProvider props.
 * @returns {React.ReactElement} - DrawerProvider component.
 */
const DrawerProvider = ({ children }) => {
    const [open, setOpen] = useState(false);
    /** @type {[string, React.Dispatch<string>]} */
    const [contentId, setContentId] = useState();
    /** @type {[JSX.Element | JSX.Element[], React.Dispatch<JSX.Element | JSX.Element[]>]} */
    const [drawerContent, setDrawerContent] = useState();

    /**
     * Sets the drawers contents or closes the drawer if its open and the same contents are present
     * @param {string} id - unique identifier for drawer content
     * @param {JSX.Element | JSX.Element[]} content - the content for the drawer
     * @returns {void}
     */
    const setDrawer = (id, content) => {
        if (id === contentId && open) {
            setOpen(false);
        } else {
            setContentId(id);
            setDrawerContent(content);
            setOpen(true);
        }
    };

    /**
     * Explicitly closes the drawer
     * @returns {void}
     */
    const close = () => setOpen(false);

    return (
        <DrawerContext.Provider value={{ setDrawer, close }}>
            {children}
            <DrawerBase open={open} onClose={() => setOpen(false)}>
                {drawerContent}
            </DrawerBase>
        </DrawerContext.Provider>
    );
};

export default DrawerProvider;
