import React from "react";
import { HelmetProvider } from "react-helmet-async";
import UnleashService from "../clients/UnleashService/UnleashService";
import { UpdateNameProvider } from "../services/AccountService";
import AuthProvider from "./auth/AuthProvider";
import AuthSessionProvider from "./authSession/AuthSessionProvider";
import DrawerProvider from "./drawer/DrawerProvider";
import EmailVerificationProvider from "./emailVerification/EmailVerificationProvider";
import FeatureFlagsProvider from "./featureFlags/FeatureFlagsProvider";
import GoogleAuthProvider from "./googleAuth/GoogleAuthProvider";
import LocalizationProvider from "./localization/LocalizationProvider";
import ModalProvider from "./modal/ModalProvider";
import PreloaderProvider from "./preloader/PreloaderProvider";
import QueryClientProvider from "./queryClient/QueryClientProvider";
import RouteProvider from "./route/RouteProvider";
import SnackbarProvider from "./snackbar/SnackbarProvider";
import ThemeProvider from "./theme/ThemeProvider";
import { TooltipProvider } from "./tooltip/TooltipProvider";

/**
 * @typedef AppProvidersProps
 * @property {React.ReactElement} children - Children.
 */

/**
 * AppProviders
 * @param {AppProvidersProps} props - AppProviders props.
 * @returns {React.ReactElement} - AppProviders component.
 */
const AppProviders = ({ children }) => {
    UnleashService.start();
    const unleashClient = UnleashService.getClient();

    return (
        <FeatureFlagsProvider unleashClient={unleashClient} startClient={false}>
            <RouteProvider>
                <GoogleAuthProvider>
                    <AuthProvider>
                        <AuthSessionProvider>
                            <QueryClientProvider>
                                <UpdateNameProvider>
                                    <EmailVerificationProvider>
                                        <ThemeProvider>
                                            <TooltipProvider>
                                                <ModalProvider>
                                                    <DrawerProvider>
                                                        <SnackbarProvider>
                                                            <LocalizationProvider>
                                                                <HelmetProvider>
                                                                    <PreloaderProvider>
                                                                        {children}
                                                                    </PreloaderProvider>
                                                                </HelmetProvider>
                                                            </LocalizationProvider>
                                                        </SnackbarProvider>
                                                    </DrawerProvider>
                                                </ModalProvider>
                                            </TooltipProvider>
                                        </ThemeProvider>
                                    </EmailVerificationProvider>
                                </UpdateNameProvider>
                            </QueryClientProvider>
                        </AuthSessionProvider>
                    </AuthProvider>
                </GoogleAuthProvider>
            </RouteProvider>
        </FeatureFlagsProvider>
    );
};

export default AppProviders;
